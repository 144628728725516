<template>
  <div class="methods">
    <div class="mt-5">
      <h5>Selecciona tu método de pago</h5>
    </div>
    <hr />
    <div class="flex flex-column">
      <!-- {{paymethods[0]}} -->
      <div v-if="paymethods" class="flex flex-wrap justify-content-between">
        <div
          v-for="data in paymethods"
          :key="data.id"
          class="inline-flex payment-method mt-2 mb-2 ml-2 mr-2 p-1"
          :class="{
            'payment-method-button-selected-color':
              paymethod_selected === data.id,
          }"
          @click="changePayMethod(data)"
        >
          <!-- <img :src="`layout/images/paymentImages/image${index + 1}.svg`"/> -->
          <img :src="getImagePath(data.name)" class="pr-2" />

          <p class="text-sm pr-2">
            {{ data.name }}
          </p>
        </div>
      </div>
    </div>
    <!-- <img :src="getImagePath(item.name)"> -->
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      default: true,
    },
    paymethods: {
      default: null,
    },
    credit_amount: {
      default: null,
    },
  },
  data() {
    return {
      paymethod_selected: null,
    };
  },
  watch: {
    credit_amount() {
      if (!this.credit_amount) {
        this.paymethod_selected = null;
      }
    },
  },
  methods: {
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";

      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    },
    changePayMethod(value) {
      if (this.credit_amount) {
        this.paymethod_selected = value.id;
      }

      this.$emit("changePayMethod", value);
    },
    getImagePath(name) {
      switch (name) {
        case "Bancamiga":
          return "layout/images/paymentImages/image1.svg";
        case "Pago móvil (Bancamiga)" || "Bancamiga":
          return "layout/images/paymentImages/image1.svg";
        case "Transferencia bancaria":
          return "layout/images/paymentImages/image2.svg";
        case "Binance Payment":
          return "layout/images/paymentImages/image3.svg";
        case "PayPal":
          return "layout/images/paymentImages/image4.svg";
        case "Zelle":
          return "layout/images/paymentImages/image5.svg";
        case "Crédito":
          return "";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.payment-method {
  /*flex: 10%;
  min-width: 33.33%;*/
  min-height: 2rem;
  font-size: 14px;
  width: min-content;
  line-height: 16px;
  color: #404040;
  cursor: pointer;
  align-items: center;
}
.payment-method:hover {
  color: #8c113e;
  font-weight: 700;
}
.payment-method:last-of-type {
  margin-right: auto !important;
}
.payment-method-button-selected-color {
  color: #ffffff !important;
  font-weight: 700;
  background: #8c113e;
  border-radius: 6px;
  img {
    filter: brightness(0) invert(1);
  }
}
h5 {
  color: #8c113e;
}
.methods {
  width: 100%;
  max-width: 100%;
}
</style>
