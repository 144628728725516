<template>
  <div class="style-background">
    <div
      class="login-background flex align-items-center justify-content-center min-h-screen overflow-hidden"
    >
      <div
        class="grid justify-content-center p-3 lg:p-0"
        style="min-width: 60%"
      >
        <div
          class="login-marco-padding col-12 mt-5 xl:mt-0 text-center logo-login"
        >
          <a href="#" @click.stop.prevent="goLandingPage">
            <img
              :src="'layout/images/logo-kiosqo.svg'"
              alt="logo"
              class="login-marco-logo mb-5 width-login"
            />
          </a>
        </div>
        <div
          class="col-12 xl:col-6 login-marco"
          style="border-radius: 12px; padding: 0.3rem"
        >
          <div class="h-full w-full m-0 py-7 px-4">
            <div class="text-center mb-5">
              <div class="text-300 text-3xl font-medium mb-3">
                Bienvenidos a Kiosqo
              </div>
              <span class="text-400 font-medium"
                >Inicia sesión para continuar</span
              >
            </div>
            <div class="w-full md:w-10 mx-auto">
              <form
                @submit.prevent="handleLogin(!v$.$invalid)"
                autocomplete="off"
                aria-autocomplete="off"
                class="p-fluid"
              >
                <span class="w-full p-float-label p-input-icon-left"
                  ><i class="pi pi-envelope text-300"></i>
                  <InputText
                    id="email"
                    v-model="v$.email.$model"
                    class="login-inputtext text-300"
                    aria-describedby="email-error"
                    aria-autocomplete="off"
                    autofocus
                  />
                  <label
                    class="login-float text-300"
                    for="email"
                    :class="{ 'p-error': v$.email.$invalid && submitted }"
                    >Correo electrónico *</label
                  >
                </span>
                <span v-if="v$.email.$error && submitted">
                  <span
                    id="email-error"
                    v-for="(error, index) of v$.email.$errors"
                    :key="index"
                  >
                    <small class="p-error">{{ error.$message }}</small>
                  </span>
                </span>
                <small
                  v-else-if="
                    (v$.email.$invalid && submitted) ||
                    v$.email.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.email.required.$message.replace("Value", "Email")
                  }}</small
                >
                <span class="w-full p-float-label p-input-icon-left mt-5"
                  ><i class="pi pi-key text-300"></i>
                  <InputText
                    id="password"
                    v-model="v$.password.$model"
                    class="login-inputtext text-300"
                    type="password"
                    autocomplete="new-password"
                  ></InputText>
                  <label
                    class="login-float text-300"
                    for="password"
                    :class="{ 'p-error': v$.password.$invalid && submitted }"
                    >Contraseña *</label
                  >
                </span>
                <small
                  v-if="
                    (v$.password.$invalid && submitted) ||
                    v$.password.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.password.required.$message.replace("Value", "Password")
                  }}</small
                >
                <!--<div class="flex align-items-center justify-content-between py-3 ">
                <div class="flex align-items-center">
                  <Checkbox v-model="checked" id="rememberme1" binary class="mr-2"></Checkbox>
                  <label for="rememberme1">Recordarme</label>
                </div>
                <a class="login-forgot-password ml-3 text-right cursor-pointer" style="color: var(--text-color)">¿Olvidaste tu contraseña?</a >
              </div>-->
                <Button
                  label="Iniciar Sesión"
                  class="button-fondo w-full mt-5 text-2xl"
                  :loading="loading"
                  type="submit"
                >
                </Button>
                <div class="flex card-container indigo-container text-center">
                  <div class="col-9 md-5 mt-5 flex-1 text-left text-300">
                    ¿No tienes cuenta en Kiosqo?
                  </div>
                  <div class="col-3 login-flex md-5 mt-5 flex-1 text-right">
                    <router-link
                      class="mr-3 text-300"
                      style="color: #373435"
                      to="signup"
                      >Registrate
                    </router-link>
                  </div>
                </div>
                <div class="flex card-container indigo-container text-center">
                  <div class="col-8 md:col-9 text-left mt-3 text-300">
                    ¿Quieres jugar sin iniciar sesión?
                  </div>
                  <div class="col-3 login-flex flex-1 text-right">
                    <Button
                      label="Autogestión"
                      class="p-button-rounded border-none w-auto font-light text-white line-height-2"
                      @click="selfmanagement"
                    >
                    </Button>
                  </div>
                </div>
                <hr />
                <div class="flex text-center justify-content-center flex-wrap">
                  <div
                    class="flex align-items-center justify-content-center mt-3"
                  >
                    <Button
                      label="Volver a inicio"
                      icon="pi pi-arrow-left"
                      class="p-button-rounded p-button-outlined font-light text-white line-height-2"
                      style="color: #cc195a; border-radius: 20px"
                      @click="goLandingPage"
                    >
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        v-model:visible="showUnlockDialog"
        :modal="true"
        :closable="false"
        :dismissableMask="false"
        :showHeader="true"
        :baseZIndex="10000"
        :style="{ width: '400px', minWidth: '360px' }"
        :header="session_locked ? 'Inicio de sesión bloqueado' : ''"
        class="otp-validation-dialog"
      >
        <transition>
          <OtpValidationForm
            v-if="session_locked"
            @sendValidationCode="unlockSession"
            @cancel="showUnlockDialog = false"
            ref="pin_form"
            :sending_validation_code="request_unlocking_session"
          >
            <template #message>
              <div
                class="mt-1 flex flex-column justify-content-center align-items-center text-center"
              >
                <IconExclamation
                  class="block mx-auto mb-3"
                  :style="{ width: '50px', height: '50px' }"
                />
                La cuenta <b>{{ email }}</b> tiene una sesión activa en otro
                dispositivo o navegador.<br /><br />
                Para continuar, ingrese el código de verificación que se envió
                por whatsapp o telegram a su teléfono registrado, para
                desbloquear el inicio de sesión en este dispositivo.
                <!-- <a class="link-sms mt-5" @click.stop.prevent="sendBySMS" href="javascript:void(0)"
                  :disabled="request_unlocking_session">Recibir código por SMS</a> -->
              </div>
            </template>
          </OtpValidationForm>
          <div
            v-else
            class="mt-5 flex flex-column justify-content-center align-items-center text-center"
          >
            <IconSuccess
              class="block mx-auto mb-3"
              :style="{ width: '80px', height: '80px' }"
            />
            <span class="text-lg font-bold">Sessión desbloqueada.</span>
            <span class="text-lg font-semibold"
              >Ahora puede iniciar sesión en este dispositivo.</span
            >
          </div>
        </transition>
        <template #footer></template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import OtpValidationForm from "@/components/ticketoffice/forms/OtpValidationForm.vue";
import IconSuccess from "@/components/svg/icons/IconSuccess.vue";
import IconExclamation from "@/components/svg/icons/IconExclamation.vue";
import HTTP from "@/service/http";

export default {
  components: {
    OtpValidationForm,
    IconSuccess,
    IconExclamation,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      loading: false,
      session_locked: false,
      showUnlockDialog: false,
      request_unlocking_session: false,
      isFunctionBlocked: false,
      landing: () => {
        window.location.href = process.env.VUE_APP_LANDING_URL;
      },
    };
  },
  methods: {
    selfmanagement() {
      // Redireccionar a autogestion de Kiosqo.tech
      window.location.href = process.env.VUE_APP_SELFMANAGEMENT_URL;
    },
    goLandingPage() {
      window.location.href = process.env.VUE_APP_LANDING_URL;
    },
    handleLogin(isFormValid) {
      this.submitted = true;
      if (!isFormValid) return;
      this.loading = true;
      this.$store
        .dispatch("auth/login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.push("/");
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 423) {
              this.session_locked = true;
              this.showUnlockDialog = true;
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail:
                  err.response.data.errors &&
                  err.response.data.errors[0] &&
                  err.response.data.errors[0].detail
                    ? err.response.data.errors[0].detail
                    : err.response.data.message,
                life: 4000,
              });
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    unlockSession(token) {
      if (!token) return;
      this.request_unlocking_session = true;
      HTTP(true)
        .post("/destroy-token", {
          email: this.email,
          token: token,
        })
        .then(() => {
          this.session_locked = false;
          setTimeout(() => {
            this.showUnlockDialog = false;
            if (!this.session_locked) this.handleLogin(true);
          }, 2500);
        })
        .catch((err) => {
          if (err.response) {
            this.$refs.pin_form.clearValidationCode();
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail: this.getFirstError(err.response.data.errors),
              life: 10000,
            });
          }
        })
        .finally(() => {
          this.request_unlocking_session = false;
        });
    },
    sendBySMS() {
      if (this.isFunctionBlocked == false) {
        this.request_unlocking_session = true;
        HTTP(true)
          .post("/sms-token", {
            email: this.email,
            password: this.password,
          })
          .then((resp) => {
            this.$toast.add({
              severity: "success",
              summary: "Código enviado",
              detail: resp.data.message,
              life: 6000,
            });
            this.$refs.pin_form.clearValidationCode();
          })
          .catch((err) => {
            if (err.response) {
              this.$refs.pin_form.clearValidationCode();
              this.$store.dispatch("changeSystemMessage", {
                severity: "error",
                summary: err.response.data.message,
                detail: this.getFirstError(err.response.data.errors),
                life: 10000,
              });
            }
          })
          .finally(() => {
            this.request_unlocking_session = false;
          });
        setTimeout(() => {
          this.isFunctionBlocked = false;
        }, 8000);
      }
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    };
  },
};
</script>
<style scoped>
.v-enter-active {
  transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.style-background {
  background: radial-gradient(50% 50% at 50% 50%, #461ec8 0%, #25007a 100%);
}

.login-background {
  background: url(/public/layout/images/wallpaper-login_signup.webp);
  background-size: cover;
}
</style>
