<template>
  <div>
    <div>
      <ul class="p-0 text-center" ref="receipt_button">
        <li>
          <Button
            class="plain-button rounded-button"
            text
            @click="toggleUserMenu"
            ><svg
              class="vector"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4212 1.45446C12.5524 0.516515 11.3391 0 9.99978 0C8.65336 0 7.43596 0.51339 6.57123 1.44553C5.69713 2.38793 5.27124 3.66873 5.37124 5.05176C5.56945 7.78031 7.64578 9.99994 9.99978 9.99994C12.3538 9.99994 14.4265 7.78076 14.6279 5.05265C14.7292 3.68212 14.3007 2.404 13.4212 1.45446ZM17.8569 19.9999H2.14269C1.937 20.0026 1.73331 19.9594 1.54642 19.8734C1.35954 19.7874 1.19417 19.6609 1.06233 19.503C0.772158 19.1561 0.655194 18.6825 0.741801 18.2035C1.11858 16.1133 2.29447 14.3575 4.14267 13.1249C5.78463 12.0307 7.86453 11.4285 9.99978 11.4285C12.135 11.4285 14.2149 12.0312 15.8569 13.1249C17.7051 14.3571 18.881 16.1129 19.2578 18.203C19.3444 18.682 19.2274 19.1557 18.9372 19.5026C18.8054 19.6606 18.6401 19.7872 18.4532 19.8732C18.2663 19.9592 18.0626 20.0025 17.8569 19.9999Z"
                fill="white"
              />
            </svg>
          </Button>
          <div v-show="showUserMenu" class="user-menu" ref="myDiv">
            <div class="user-menu-avatar p-3" v-if="role">
              <div class="grid">
                <div class="col-3">
                  <IconAvatar />
                </div>
                <div class="col-7 pl-3">
                  <span class="avatar-name mt-2">Hola {{ role }}</span>
                  <br />
                  <span class="avatar-user">{{ email }}</span>
                </div>
              </div>
            </div>
            <div class="user-menu-items pb-1 mt-2">
              <ul class="p-0">
                <li
                  v-for="(item, index) in userMenuItems.filter((e) => !e.hide)"
                  :key="index"
                  class="mb-2"
                  :class="item.li"
                  @click="() => (item.command ? item.command() : false)"
                >
                  <div class="grid p-1 pl-4" :class="item.grid">
                    <div class="col-1 text-center">
                      <i v-if="item.icon" :class="item.icon"></i>
                      <component
                        v-else
                        :is="item.iconComponent"
                        :class="item.iconClass"
                      ></component>
                    </div>
                    <div class="col-11 text-left">
                      <p class="pl-1 item-text" :class="item.labelClass">
                        {{ item.label }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- <IconNotification /> -->

    <!-- <a
      href="https://api.whatsapp.com/send?phone=584246466682"
      target="_blank"
      margin-top="35px"
      display="block"
      class="h iconSupport"
    >
      <IconSupport />
    </a> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import IconAvatar from "@/components/svg/icons/IconAvatar.vue";
import IconNotification from "@/components/svg/icons/IconNotification.vue";
import IconSupport from "@/components/svg/icons/IconSupport.vue";
import IconHelp from "@/components/svg/icons/IconHelp.vue";
import IconProfile from "@/components/svg/icons/IconProfile.vue";
import IconSetting from "@/components/svg/icons/IconSetting.vue";
import IconReport from "@/components/svg/icons/IconReport.vue";
import IconSignOff from "@/components/svg/icons/IconSignOff.vue";
import IconSearch from "@/components/svg/icons/IconSearch.vue";
import IconTicket from "@/components/svg/icons/IconTicket.vue";

import menuGuest from "@/menus/invitado";
import EventBus from "@/AppEventBus";

export default {
  components: {
    IconAvatar,
    IconNotification,
    IconSupport,
    IconHelp,
    IconProfile,
    IconSetting,
    IconReport,
    IconSignOff,
    IconSearch,
    IconTicket,
  },
  data() {
    return {
      showUserMenu: false,
      userMenuItems: [
        {
          label: "Inicio",
          icon: "pi pi-fw pi-home icon-color",
          command: () => {
            this.toggleUserMenu();
            this.$router.push("/");
          },
        },
        {
          label: "Perfil",
          iconClass: "pt-1",
          iconComponent: "IconProfile",
          hide: this.$store.getters["auth/role"] !== "agencia",
          command: () => {
            this.toggleUserMenu();
            this.$router.push("/profile");
          },
        },
        // {
        //   label: "Reportes",
        //   iconClass: "pt-1",
        //   iconComponent: "IconReport",
        // },
        // {
        //   label: "Ajustes",
        //   iconClass: "pt-1",
        //   iconComponent: "IconSetting",
        // },
        // {
        //   label: "Ayuda",
        //   grid: "mt-5 mb-2",
        //   iconClass: "pt-1",
        //   iconComponent: "IconHelp"
        // },
        {
          label: "Cerrar sesión",
          labelClass: "text-white font-bold mt-1",
          grid: "mt-5 mb-2 box-color p-2",
          li: "h-0 ml-2 mr-1",
          iconClass: "pt-1",
          iconComponent: "IconSignOff",
          command: () => {
            this.toggleUserMenu();
            this.$router.push("/logout");
          },
        },
      ],
    };
  },
  mounted() {
    //this.addItemsMenu();
    document.addEventListener("click", this.handleClickOutside);
    window.addEventListener("blur", () => {
      this.showUserMenu = false;
    });
  },
  methods: {
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },
    handleClickOutside() {
      if (
        this.$refs.myDiv &&
        !this.$refs.myDiv.contains(event.target) &&
        !this.$refs.receipt_button.contains(event.target)
      ) {
        this.showUserMenu = false;
      }
    },
    addItemsMenu() {
      if (!this.role) {
        menuGuest.menu.forEach((item) => {
          if (item.to) {
            item.command = () => {
              this.toggleUserMenu();
              this.$router.push(item.to);
            };
          }
        });

        this.userMenuItems = menuGuest.menu;
        return;
      }

      import(`../../menus/${!this.ticketoffice_online ? this.role : 'ticketoffice_online'}.js`)
        .then((m) => {
          m.default.menu.forEach((item, index) => {
            if (item.to) {
              item.command = () => {
                this.toggleUserMenu();
                this.$router.push(item.to);
              };
            }

            if (item.dispatch) {
              item.command = () => {
                this.toggleUserMenu();
                this.$store.dispatch(item.dispatch);
              };
            }

            if (item.showsidebar) {
              item.command = () => {
                this.toggleUserMenu();
                EventBus.emit("show_sidebar", item.showsidebar);
              };
            }

            this.userMenuItems.splice(index + 2, 0, item);
          });
        })
        .catch((err) => {
          console.log("Error loading user menu: ", err);
        });
    },
  },
  computed: {
    ...mapGetters("auth", ["role", "email", "ticketoffice_online"]),
  },
  watch: {
    role: {
      handler: function (newVal, oldVal) {
        if (newVal && oldVal !== newVal) this.addItemsMenu();
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

.box-item-user-options-menu {
  width: 3.5em;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.icon-color {
  color: #cc195a;
}

.box-color {
  background: #4600C8;
  color: #fff;
  box-sizing: border-box;
  max-width: 239px;
  &:hover {
    background: #1E0064;
  }
}

.h-0 {
  height: 0em;
}

.icon {
  width: 1.5625em;
  display: block;
  margin: 1.2em auto;
  cursor: pointer;
}

.icon-bars {
  color: #cc195a;
  font-size: 1.8em;
  text-align: center;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}

.icon-support,
.icon-notification {
  fill: #cc195a;
}

.icon-support:hover,
.icon-notification:hover,
icon-bars:hover {
  fill: #8c113e;
}

.p-button:hover,
.p-button:focus {
  background: #fff;
}

.box-user-menu-items {
  left: 1360px !important;
  width: 16em !important;
}

.user-menu {
  position: absolute;
  background: #fff;
  border-radius: 0.4em;
  width: 17em;
  right: 0.5em;
  box-shadow: 0px 0px 10px -4px #212121;
}

.avatar-name {
  color: #cc195a;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  width: 100%;
  text-align: left;
}

.user-menu-avatar {
  background: #ededed;
}

.avatar-user {
  color: #404040;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  width: 100%;
  text-align: left;
}

.user-menu-items ul li {
  cursor: pointer;
}

.user-menu-items ul li:hover {
  background: #ededed;
}

.item-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #404040;
}

li div .box-color:hover {
  background: #85092c;
}

@media screen and (max-width: 912px) {
  .box-item-user-options-menu {
    width: auto;
  }
}

@media screen and (max-width: 650px) {
  .box-item-user-options-menu {
    width: auto;
    border-width: 0px !important;
    border-radius: 0px !important;
    background: #f5f5f5;
    margin-right: 3px;
    margin-left: 3px;
  }
  .box-item-user-options-menu .p-button {
    background: #f5f5f5 !important;
  }
  .iconSupport {
    display: none !important;
  }
}
</style>
