<template>
  <div class="layout-footer notprintContent" v-if="!($windowSize.width <= 600)">
    <div class="footer-info">
      <div class="footer-copyright"
        >© 2023 TEC-FIN 212, C.A | Todos los derechos reservados.</div
      >
      <div class="footer-support"
        >Soporte: soporte@kiosqo.tech | Atención al cliente:
        info@kiosqo.tech</div
      >
    </div>
  </div>
  <div v-else class="layout-footer notprintContent">
    <kiosco-footer-bar />
  </div>
</template>

<script>
import KioscoFooterBar from "@/components/mobile/KioscoFooterBar.vue";
export default {
  name: "AppFooter",
  components: {
    KioscoFooterBar
  },
  methods: {
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
<style lang="scss">
.footer-logo {
  position: absolute;
  left: 25px;
  width: 40px;
  height: 40px;
}
.footer-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  color: #ffffff;
  font: 300 12px "Roboto-Light", sans-serif;
  gap: 20px;
}
/* .footer-copyright {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin: 3px;
}
.footer-support {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin: 3px;
} */
</style>
