<template>
  <div class="landing-layout-topbar md:px-8 flex align-items-center justify-content-between relative lg:fixed">
    <a class="flex align-items-center" href="#"> <img :src="'layout/images/logo-kiosqo.png'" alt="Logo" width="185"
        height="50" class="mr-8" /></a>
    <a class="cursor-pointer block lg:hidden text-50 p-ripple" v-ripple
      v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
      <i class="pi pi-bars text-4xl"></i>
    </a>
    <div
      class="align-items-center background-menu flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-5 md:px-5 lg:px-0 z-2"
      style="top: 90px">
      <ul
        class="list-none p-0 m-0 flex align-items-center justify-content-center select-none flex-column lg:flex-row cursor-pointer">
        <!--<li>
          <MegaMenu class="text-50 border-none button-megamenu-landing" :model="megamenuItems" />
        </li>
        <li>
          <span class="p-input-icon-left p-input-icon-right py-4 md:py-0">
            <i class="pi pi-user" />
            <InputText type="text" class="p-imput-border" placeholder="Search" />
            <i class="pi pi-search" />
          </span>
        </li>-->
      </ul>
      <div
        class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
        <Button label="Registrate" @click="$router.push('/signup')" class="button-simple text-lg px-4 m-2"> </Button>
        <Button label="Inicia sesión" @click="$router.push('/login')" class="button-fondo text-lg px-4 m-2"> </Button>
      </div>
    </div>
  </div> 
</template>