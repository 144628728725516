<template>
<svg 
  width="25" 
  height="27" 
  viewBox="0 0 25 27" 
  fill="none" 
  xmlns="http://www.w3.org/2000/svg"
>
  <path 
    d="M16.5216 0H15.7193C15.5156 0 15.3282 0.113179 15.2352 0.294266L13.3702 3.91474L12.0435 6.49019L11.2865 7.959C10.6376 9.25805 9.31464 10.3949 7.90493 10.3949H3.99145V4.83275C3.99145 4.53219 3.74748 4.28823 3.44819 4.28823C2.49623 4.28823 1.63355 4.6743 1.00981 5.29804C0.386066 5.92304 0 6.78571 0 7.73768V15.6388C0 20.3018 3.81539 24.1185 8.47963 24.1185H12.3717C12.7767 24.1185 13.0395 23.6922 12.8584 23.33L11.4374 20.5018C11.3443 20.3182 11.1569 20.2025 10.9507 20.2025H9.71831C6.56942 20.2025 3.99271 17.6258 3.99271 14.4769V13.872C5.29301 13.872 6.59205 13.8431 7.8911 13.872C9.77867 13.9135 11.0991 14.8038 11.8951 16.3745C12.5277 17.622 13.1564 18.8707 13.7852 20.1207L13.8267 20.2025L15.7948 24.1185L16.2689 25.0616C16.4789 25.4791 16.6889 25.8966 16.8989 26.3141C16.9907 26.4977 17.1793 26.6134 17.3856 26.6134H20.5621C20.9683 26.6134 21.2299 26.1859 21.0475 25.8237L19.8428 23.4356C22.8672 22.1366 25 19.1247 25 15.6376V8.47837C25 3.81539 21.1846 0 16.5216 0ZM21.0085 14.4769C21.0085 16.6876 19.7384 18.6154 17.8924 19.5687L15.8727 15.5621C14.8252 13.4847 13.3275 12.1818 11.2877 11.8964C12.9074 11.2764 14.3121 10.2213 15.2515 8.4746C15.9872 7.10513 16.6235 5.6841 17.3101 4.28823C19.4668 5.11066 21.0098 7.20448 21.0098 9.6416V14.4756L21.0085 14.4769Z"
    fill="white"
  />
</svg>
</template>
