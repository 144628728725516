import session from "@/service/session";
import HTTP from "@/service/http";
import { store } from "@/store";

const allRoles = [
  "administrador",
  "analista-ldo",
  "operadora",
  "comercializadora",
  "taquilla",
  "analista-lotomax",
  "usuario",
  "agencia",
];

const autoLogin = (next) => {
  HTTP(true)
    .get("/auto-login")
    .then((response) => {
      if (response.status === 200) {
        store.commit("auth/auth_success", {
          data: {
            rol: response.data.data.rol,
            access: response.data.data.access,
            refresh: response.data.data.access,
            uuid: response.data.data.uuid,
            email: "",
          },
        });
        setTimeout(()=>{ next("/ticketoffice"); }, 0)
        // setTimeout(()=>{ next(); }, 0)
        // setTimeout(() => {
        //   window.location.href = "/ticketoffice";
        // }, 0);
      }
    })
    .catch((error) => {
      console.log(error);
      next("/login");
    });
};

export const routeDevelopment = [
  {
    path: "/",
    //redirect: "/ticketoffice",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    meta: {
      requireAuth: true,
      title: "Inicio",
      roles: ["taquilla", "usuario"],
    },
    beforeEnter: (to, from, next) => {
      let user = session.getUserData();
      if (user.rol === "usuario")
        next({
          path: "/profile",
        });
      else next("/ticketoffice");
    }
    /* children: [
      {
        path: "/landing",
        name: "landing", */
    //component: () => import(/* webpackChunkName: "landing" */"@/pages/Landing.vue"),
    /*   }
    ] */
  },
  {
    path: "/",
    redirect: "/termsandconditions",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/termsandconditions",
        name: "termsandconditions",
        component: () =>
          import(
            /* webpackChunkName: "termsandconditions" */ "@/pages/TermsandConditions.vue"
          ),
      },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
    redirect: "/ticketoffice",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/components/Dashboard.vue"),
    meta: {
      requireAuth: true,
      title: "Inicio",
      roles: allRoles,
    },
    /* beforeEnter: (to, from, next) => {
      let user = session.getUserData();
      if (user.rol === "taquilla")
        next({
          path: "/ticketoffice",
        });
      else next();
    }, */
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/views/profile/UserProfile.vue"
      ),
    meta: {
      requireAuth: true,
      title: "Perfil",
      roles: ["taquilla", "usuario"],
    },
  },
  {
    path: "/ticketoffice",
    name: "ticketoffice",
    // redirect: "/ticketoffice-online",

    component: () =>
      import(
        /* webpackChunkName: "ticketoffice" */ "@/views/ticketoffice/TicketOffice.vue"
      ),
    meta: {
      requireAuth: true,
      title: "Inicio",
      roles: ["taquilla", "usuario"],
    },
  },
  {
    path: "/ticketoffice-online",
    name: "ticketoffice-online",
    component: () =>
      import(
        /* webpackChunkName: "ticketoffice" */ "@/views/ticketoffice/TicketOffice.vue"
      ),
    meta: {
      title: "Taquilla Online",
    },
    beforeEnter: (to, from, next) => {
      if (session.userIsLogged()) {
        store
          .dispatch("auth/logout")
          .then(() => {
            autoLogin(next);
          })
          .catch();
      } else {
        store.commit("auth/logout_session");
        autoLogin(next);
      }
    },
  },
  {
    path: "/users",
    name: "userlist",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/users/UsersList.vue"),
    requireAuth: true,
    meta: {
      requireAuth: true,
      title: "Usuarios",
      roles: ["administrador"],
    },
  },
  {
    path: "/operadoras",
    name: "operatorlist",
    component: () =>
      import(
        /* webpackChunkName: "operators" */ "@/views/operators/OperatorsList.vue"
      ),
    requireAuth: true,
    meta: {
      requireAuth: true,
      title: "Operadoras",
      roles: ["administrador", "analista-ldo"],
    },
  },
  {
    path: "/comercializadoras",
    name: "marketerlist",
    component: () =>
      import(
        /* webpackChunkName: "marketers" */ "@/views/marketers/MarketersList.vue"
      ),
    requireAuth: true,
    meta: {
      requireAuth: true,
      title: "Comercializadoras",
      roles: ["analista-ldo", "operadora"],
    },
  },
  {
    path: "/agencias",
    name: "agencieslist",
    component: () =>
      import(
        /* webpackChunkName: "agencies" */ "@/views/agencies/AgenciesList.vue"
      ),
    requireAuth: true,
    meta: {
      requireAuth: true,
      title: "Agencias",
      roles: ["analista-ldo", "comercializadora"],
    },
  },
  {
    path: "/transactions",
    name: "transactionslist",
    component: () =>
      import(
        /* webpackChunkName: "transactions" */ "@/views/transactions/TransactionsList.vue"
      ),
    requireAuth: true,
    meta: {
      requireAuth: true,
      title: "Transacciones",
      roles: ["analista-lotomax"],
    },
  },
  {
    path: "/config/exchange-rates",
    name: "exchangerates",
    component: () =>
      import(
        /* webpackChunkName: "config-exchangerates" */ "@/views/configurations/ExchangeRates.vue"
      ),
    requireAuth: true,
    meta: {
      requireAuth: true,
      title: "Tasas",
      roles: ["analista-lotomax"],
    },
  },
  {
    path: "/operator-register",
    name: "operator-register",
    component: () =>
      import(
        /* webpackChunkName: "operator-register" */ "@/components/operator/register/OperatorRegistration.vue"
      ),
    redirect: "/operator-register/1",
    children: [
      {
        path: ":step",
        component: () =>
          import(
            /* webpackChunkName: "operator-register" */ "@/components/operator/register/OperatorRegisterForm.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/marketer-register",
    name: "marketer-register",
    component: () =>
      import(
        /* webpackChunkName: "marketer-register" */ "@/components/marketer/register/MarketerRegistration.vue"
      ),
    redirect: "/marketer-register/1",
    children: [
      {
        path: ":step",
        component: () =>
          import(
            /* webpackChunkName: "marketer-register" */ "@/components/marketer/register/MarketerRegisterForm.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/agency-register",
    name: "agency-register",
    component: () =>
      import(
        /* webpackChunkName: "agency-register" */ "@/components/agency/register/AgencyRegistration.vue"
      ),
    redirect: "/agency-register/1",
    children: [
      {
        path: ":step",
        component: () =>
          import(
            /* webpackChunkName: "agency-register" */ "@/components/marketer/register/MarketerRegisterForm.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/Login.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "@/pages/SignUp.vue"),
  },
  {
    path: "/pwdsignup",
    name: "pwd_signup",
    component: () =>
      import(/* webpackChunkName: "pwd_signup" */ "@/pages/PwdSignUp.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () =>
      import(/* webpackChunkName: "access" */ "@/pages/Access.vue"),
  },
  {
    path: "/notfound",
    name: "notfound",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "@/pages/NotFound.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/notfound",
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      import(/* webpackChunkName: "logout" */ "@/pages/LogOut.vue"),
    meta: {
      requireAuth: true,
      title: "Cerrar Sesión",
      roles: allRoles,
    },
  },
];

export const beforeEachDevelopment = (to, from, next) => {
  document.title = `${to.meta.title ? to.meta.title + " - " : ""}  Kiosqo`;
  if (to.name === "ticketoffice-online") {
    next();
    return;
  }
  if (!to.meta.requireAuth) {
    if (
      (to.name === "login" ||
        to.name === "landing" ||
        to.name === "termsandconditions") &&
      session.userIsLogged()
    ) {
      next({
        path: "/ticketoffice",
      });
    } else next();
  } else {
    if (!session.userIsLogged()) {
      next({
        path: "/login",
      });
    } else {
      let user = session.getUserData();
      if (to.meta.roles && to.meta.roles.includes(user.rol)) next();
      else
        next({
          path: "/access",
        });
    }
  }
};
