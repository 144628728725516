<script setup>
import { computed } from 'vue';

const logoUrl = computed(() => {
  return `/layout/images/logo-kiosqo.svg`;
});
</script>

<template>
  <div class="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
    style="background: url(/layout/images/404.jpg)">
    <div class="flex flex-column align-items-center justify-content-center">
      <a @click="$router.push('/')"> <img :src="logoUrl" alt="Kiosqo logo" height="50" class="flex-shrink-0" /></a>
      <div style="padding: 1rem;">
        <div class="w-full px-5 sm:px-8 flex flex-column align-items-center">
          <span class="text-100 font-bold text-8xl">404</span>
          <h1 class="text-50 font-bold text-3xl lg:text-5xl mt-4 mb-2">Hey</h1>
          <h1 class="text-50 font-bold text-3xl lg:text-5xl mt-0 mb-3">¡estas extraviado!</h1>
          <div class="text-100 text-xl mb-5">su solicitud no está disponible.</div>
          <router-link to="/" class=" flex align-items-center py-5 border-300 border-bottom-1">
            <span class="flex justify-content-center align-items-center bg-cyan-400 border-round"
              style="height: 3.5rem; width: 3.5rem">
              <i class="text-50 pi pi-fw pi-table text-2xl"></i>
            </span>
            <span class="ml-4 flex flex-column">
              <span class="text-100 lg:text-xl font-medium mb-0 block">Preguntas frecuentes</span>
              <span class="text-400 lg:text-xl">Puedes utilizar esta opción para encontrar lo que
                necesitas.</span>
            </span>
          </router-link>
          <div class="col-12 md:col-8 mt-4 justify-content-center">
            <div class="grid justify-content-center">
              <span class="ml-4 flex flex-column">
                <span class="text-100 lg:text-xl font-medium mb-0 block">Redes Sociales</span>
              </span>
              <div class="flex col-12 md:col-12 md:mt-0 justify-content-center">
                <a href="https://www.facebook.com/profile.php?id=100095607240586" target="_blank"><i
                    class="pi pi-facebook px-3 hover:text-100" style="font-size: 2.5rem; color: #250074"></i></a>
                <a href="https://www.instagram.com/kiosqotech/" target="_blank"><i
                    class="pi pi-instagram px-3  hover:text-100" style="font-size: 2.5rem; color: #250074"></i></a>
                <a href="https://twitter.com/@Kiosqotech" target="_blank"><i class="pi pi-twitter px-3 hover:text-100"
                    style="font-size: 2.5rem; color: #250074"></i></a>
                <a href="https://www.youtube.com/channel/UC1ztx3L9wxEt4Qjo4YXJrHA" target="_blank"><i
                    class="pi pi-youtube px-3 hover:text-100" style="font-size: 2.5rem; color: #250074"></i></a>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-8 mt-4 justify-content-center">
            <div class="grid justify-content-center">
              <span class="ml-4 flex flex-column">
                <Button @click="$router.push('/')" label="Volver a la ppagina de inicio"
                  class="p-button-rounded border-none ml-5 font-light text-white line-height-2"></Button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
