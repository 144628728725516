<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="#8C113E"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 0C6.72909 0 0 6.72909 0 15C0 23.2709 6.72909 30 15 30C23.2709 30 30 23.2709 30 15C30 6.72909 23.2709 0 15 0ZM16.4423 23.0704H13.5577V20.1858H16.4423V23.0704ZM16.1538 18.4615H13.8462L13.4135 6.92308H16.5865L16.1538 18.4615Z"
    />
  </svg>
</template>
