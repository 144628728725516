<template>
  <div class="landing-footer pt-6">
    <div class="pb-4 px-4 md:px-0 md:px-8 mx-0 lg:mx-8" id="contact">
      <div class="grid">
        <div class="col-12 md:col-3" style="margin-top: 0rem">
          <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
            <img :src="'layout/images/logo-kiosqo.png'" alt="footer sections" width="250" class="mr-2" />
            <h4 class="font-medium text-3xl text-900"></h4>
          </div>
        </div>
        <div class="col-12 md:col-8">
          <div class="grid text-center md:text-left">
            <div class=" col-12 md:col-3">
              <h4 class="font-medium text-2xl line-height-3 mb-3 text-50">Sobre Kiosqo</h4>
              <hr>
              <a class="line-height-3 text-base block cursor-pointer mb-2 text-400">Nosotros</a>
            </div>
            <div class=" col-12 md:col-3 mt-4 md:mt-0">
              <h4 class="font-medium text-2xl line-height-3 mb-3 text-50">Recursos</h4>
              <hr>
              <a class="line-height-3 text-base block cursor-pointer mb-2 text-400">
                Manual de usuario <img src="layout/images/new-badge.svg" class="ml-2" width="30" height="13" /> 
              </a>
              <a class="line-height-3 text-base block cursor-pointer mb-2 text-400"  @click="$router.push('/termsandconditions')">Terminos y condiciones</a>
            </div>
            <div class=" col-12 md:col-3 mt-4 md:mt-0">
              <h4 class="font-medium text-2xl line-height-3 mb-3 text-50">
                Communidad
              </h4>
              <hr>
              <a class="line-height-3 text-base block cursor-pointer text-400">Blog</a>
            </div>
            <div class="col-12 md:col-3 mt-4 md:mt-0">
              <h4 class="font-medium text-2xl line-height-3 mb-3 text-50">
                Síguenos
              </h4>
              <hr>
              <a href="https://www.facebook.com/profile.php?id=100095607240586" target="_blank"><i
                  class="pi pi-facebook px-3" style="font-size: 2rem; color: #ff824a;"></i></a>
              <a href="https://www.instagram.com/kiosqotech/" target="_blank"><i
                  class="pi pi-instagram px-3" style="font-size: 2rem;color: #ff824a;"></i></a>
              <a href="https://twitter.com/@Kiosqotech" target="_blank"><i 
                  class="pi pi-twitter px-3" style="font-size: 2rem; color: #ff824a;"></i></a>
              <a href="https://www.youtube.com/channel/UC1ztx3L9wxEt4Qjo4YXJrHA" target="_blank"><i
                  class="pi pi-youtube px-3" style="font-size: 2rem; color: #ff824a;"></i></a>
              <a href="https://www.tiktok.com/upload?lang=es" target="_blank"><i class="pi pi-tiktok px-3"
                  style="font-size: 2rem"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="landing-footer-copyright px-4 md:px-8 lg:px-8 py-4">
      <div class="col-12 md:col-6 lg:col-6 text-center md:text-left">© 2023 Kiosqo.tech | Todos los derechos
        reservados
      </div>
      <div class="col-12 md:col-6 lg:col-6 text-center md:text-right lg:text-right landing-footer-links">Desarrollado
        por:<a class="landing-link" href="https://tecfin212.com" target="_blank">Tec-Fin212,C.A</a></div>
    </div>
  </div>
</template>

