<template>
  <div class="w-full">
    <div v-show="!loading" class="w-full">
      <p class="instrcciontes-title">Instrucciones</p>
      <ul class="pl-4">
        <li>
          El depósito mínimo es de <b>2,00 VES</b>
          <!-- <img class="ml-1 w-1rem h-1rem" src="/images/busd12.png" /> -->
        </li>
      </ul>
    </div>
    <div
      class="text-center flex-column sm:flex-row justify-content-center w-full"
      v-show="!loading"
    >
      <div v-if="false" class="inline-flex flex-column mr-0 sm:mr-2">
        <label class="font-medium" for="">Monto en USD</label>
        <InputText
          class="new-input mt-3 text-center"
          id="i_credit"
          placeholder="0.00 (USD)"
          autocomplete="off"
          :min="2"
          @input="convertirDolaresABolivares"
          @keypress="validNumericInput"
          @paste="preventNonNumericInput"
          :max="10000"
          v-model="v$.credit_amount.$model"
          style="height: 29px; font-size: 16px"
          ref="amountDepositeCredit"
          :class="{
            'p-invalid': v$.credit_amount.$invalid,
          }"
          :maxlength="9"
          inputmode="numeric"
          autofocus
        />
      </div>
      <div class="inline-flex flex-column ml-0 sm:ml-2 mt-5 sm:mt-0">
        <label class="font-medium" for="">Monto en Bolívares</label>
        <InputText
          suffix=" BS"
          class="new-input mt-3 text-center"
          id="i_credit"
          @input="convertirBolivaresADolares"
          @keypress="validNumericInput"
          @paste="preventNonNumericInput"
          placeholder="0.00 (VES)"
          autofocus
          autocomplete="off"
          :min="2"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          :showButtons="false"
          v-model="v$.amount_to_pay_ves.$model"
          style="height: 29px; font-size: 16px"
          ref="amountDepositeCredit"
          :class="{
            'p-invalid': v$.amount_to_pay_ves.$invalid,
          }"
          inputmode="numeric"
        />
        <div
          v-if="
            (v$.amount_to_pay_ves.$invalid && submitted) ||
            v$.amount_to_pay_ves.$pending.$response
          "
        >
          <small class="p-error">Monto requerido.</small>
        </div>
      </div>
    </div>
    <div class="mx-1 mt-2" v-show="!loading">
      <PayMethods
        :loading="!this.paymethods"
        :paymethods="this.paymethods"
        :credit_amount="this.credit_amount"
        @changePayMethod="changePayMethod"
      />
      <hr />
    </div>

    <TransferPayment
      :paymethod_selected="this.paymethod_selected"
      :credit_amount="this.credit_amount"
      :loading_confirm_button="this.loading_confirm_button"
      :withdraw="false"
      :required_fields="this.required_fields"
      @sendPaymentRequest="sendDepositRequest"
      v-show="this.credit_amount && Number(this.credit_amount) > 0 && !loading"
    />
    <!-- <TransactionStatus
      :credit_amount="this.credit_amount"
      v-show="this.send_credits == true"
      @cancel="this.send_credits = false"
    /> -->
    <ProgressSpinner mode="indeterminate" class="w-full" v-show="loading" />
  </div>
</template>

<script>
import HTTP from "@/service/http";
import PayMethods from "./PayMethods";
import TransferPayment from "./TransferPayment";
// import TransactionStatus from "./TransactionStatus";
import ProgressSpinner from "primevue/progressspinner";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    PayMethods,
    TransferPayment,
    ProgressSpinner,
    // TransactionStatus,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      send_credits: false,
      required_fields: [
        "reference",
        "date",
        "phone",
        "bank",
        "real_amount_to_pay",
        "paymethod_detail",
      ],
      show_validation_alert: false,
      paymethods: null,
      paymethod_selected: null,
      credit_amount: null,
      loading_confirm_button: false,
      loading: true,
      submitted: false,
      amount_to_pay_ves: null,
    };
  },
  computed: {
    ...mapState("ticketoffice", ["monedaOptions", "getUserBalance"]),
    ...mapMutations("ticketoffice", ["setGetUserBalance"]),
    ammount_to_pay() {
      if (this.credit_amount && this.paymethod_selected) {
        return (
          parseFloat(
            this.credit_amount * this.paymethod_selected.convert_amount
          ).toFixed(2) + ` ${this.paymethod_selected.currency_acronym}`
        );
      }

      return 0;
    },
  },
  created() {
    this.getPaymentMethodsDepositCredits();
  },
  methods: {
    validateCreditAmount() {
      if (!this.credit_amount) {
        this.paymethod_selected = null;
      }
    },
    convertirDolaresABolivares: function () {
      this.amount_to_pay_ves = (
        Number(this.credit_amount) * this.convert(1)
      ).toFixed(2);
    },
    convertirBolivaresADolares: function () {
      // TODO: verificar la conversion para cuando se usen usd
      // this.credit_amount = Number(this.amount_to_pay_ves) / this.convert(1);
      this.credit_amount = Number(this.amount_to_pay_ves);
    },
    changePayMethod(value) {
      if (this.credit_amount) {
        this.paymethod_selected = value;
        this.show_validation_alert = false;
      } else {
        this.show_validation_alert = true;
        this.$refs.amountDepositeCredit.$el.focus();
        this.submitted = true;
      }
    },
    convert(amount) {
      let moneda = this.monedaOptions.find((e) => e.code === 1);
      return !isNaN(amount) && moneda && !isNaN(moneda.exchange_rate)
        ? Number(amount) * Number(moneda.exchange_rate)
        : Number(0);
    },
    validNumericInput(event) {
      if (
        !/\d/.test(event.key) &&
        (event.key !== "." || /\./.test(event.target.value))
      )
        return event.preventDefault();
    },
    preventNonNumericInput(event) {
      event.preventDefault();
      const text = event.clipboardData.getData("text/plain");
      const numeric = text.replace(/[^\d]/g, "");
      document.execCommand("insertText", false, numeric);
    },
    sendDepositRequest(data) {
      this.send_credits = true;
      return new Promise((resolve, reject) => {
        this.loading_confirm_button = true;
        HTTP()
          .post("/transactions/deposit", data)
          .then((resp) => {
            this.$toast.add({
              severity: "success",
              detail: resp.data.message,
              life: 3000,
            });
            this.$emit("cancel");
            this.$store.commit("ticketoffice/setGetUserBalance", {
              balance: parseFloat(resp.data.data.balance).toFixed(2),
            });
            resolve(resp);
          })
          .catch((err) => {
            this.$toast.add({
              severity: "error",
              summary: err.response.data.status,
              detail: err.response.data.message,
              life: 3000,
            });
            reject(err);
          })
          .finally(() => (this.loading_confirm_button = false));
      });
    },
    getPaymentMethodsDepositCredits() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/paymentmethods?deposit-credits")
          .then((resp) => {
            this.paymethods = resp.data.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => (this.loading = false));
      });
    },
  },
  validations() {
    return {
      credit_amount: {
        required,
      },
      amount_to_pay_ves: {
        required,
      },
    };
  },
};
</script>

<style scoped>
.instrcciontes-title {
  font-size: 16px;
  font-weight: 600;
  color: #404040;
  font-family: Roboto;
  line-height: 19px;
  letter-spacing: 0em;
}
li {
  color: #404040;
}
:deep .new-input {
  border-style: none;
  border-bottom: 1px solid rgb(204, 25, 90);
  border-radius: 0;
}
</style>
