<template>
  <div class="flex flex-wrap justify-content-between align-items-center w-full">
    <!-- <button
      v-if="!ticketoffice_online"
      @click="showCreditModal"
      class="flex flex-column justify-content-center align-items-center button-wallet"
      :class="{ 'w-full': ['usuario'].includes(role) }"
    >
      <div class="icon-up"><i class="pi pi-angle-up" /></div>
      <span>Wallet</span>
      <span
        ><img
          width="20"
          height="19"
          src="/images/binance/icons/binance-busd.svg"
          class="mr-1"
        />{{ Number(getTotalUserBalance).toLocaleString("es-VE") }}</span
      >
    </button> -->
    <!-- <button
      v-if="['taquilla'].includes(role) && ticketoffice_online"
      @click="toggleShowReceipt"
      class="flex flex-column justify-content-center align-items-center button-ticket"
      :class="{ 'w-full': ticketoffice_online }"
    >
      <div class="icon-up"><i class="pi pi-angle-up" /></div>
      <span>Ver Ticket</span>
      <span
        >BS.
        {{
          currentPlayData &&
          currentPlayData.ticket &&
          !isNaN(currentPlayData.ticket.debt)
            ? Number(
                Math.floor(currentPlayData.ticket.debt * 100) / 100
              ).toLocaleString("es-VE")
            : Number(0).toFixed(2).toLocaleString("es-VE")
        }}
      </span>
    </button> -->
    <Sidebar
      v-model:visible="show_ticket"
      position="bottom"
      :blockScroll="true"
      :style="'height: calc(100%)'"
    >
      <template #header>
        <div
          class="flex flex-row justify-content-start align-items-center w-full pb-2"
        >
          <div class="flex flex-row justify-content-start align-items-center">
            <IconTicket class="mr-2" width="18" height="23" />
            <span class="font-bold">Detalle del Ticket</span>
          </div>
        </div>
      </template>
      <TicketPreview
        scrollHeight="calc(100vh - 420px)"
        scrollMaxHeight="100%"
        :showPayButton="true"
      />
    </Sidebar>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import TicketPreview from "@/components/ticketoffice/TicketPreview.vue";
import Sidebar from "primevue/sidebar";
import IconTicket from "@/components/svg/icons/IconTicket.vue";
import EventBus from "@/AppEventBus";

export default {
  components: {
    TicketPreview,
    Sidebar,
    IconTicket,
  },
  data() {
    return {
      show_ticket: false,
    };
  },
  methods: {
    ...mapMutations("ticketoffice", ["setToggleTicketPreview"]),
    toggleShowReceipt() {
      this.show_ticket = !this.show_ticket;
    },
    showCreditModal() {
      EventBus.emit("show_credit_modal");
    },
  },
  computed: {
    ...mapState("ticketoffice", [
      "showTicketPreview",
      "showPrint",
      "userBalance",
      "monedaOptions",
      "payDialog",
    ]),
    ...mapGetters("ticketoffice", ["currentPlayData", "getTotalUserBalance"]),
    ...mapGetters("auth", ["role", "ticketoffice_online"]),
  },
  watch: {
    payDialog(val) {
      if (val) {
        this.show_ticket = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wallet {
  background-color: #ededed;
  max-width: 100%;
  min-width: 50%;
  height: 80px;
  border: 0;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  position: relative;
  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #404040;
    margin-bottom: 2px;
    z-index: 0;
  }
  img {
    vertical-align: middle;
  }
  .icon-up {
    width: 27px;
    height: 27px;
    background: #ededed;
    border-radius: 50px;
    position: absolute;
    top: -7px;
    box-shadow: 0px -8px 8px -8px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px -8px 8px -8px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px -8px 8px -8px rgba(0, 0, 0, 0.25);
    .pi {
      font-size: 18px;
      color: #404040;
    }
  }
}
.button-ticket {
  background-color: #404040;
  max-width: 100%;
  min-width: 50%;
  height: 80px;
  border: 0;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  position: relative;
  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 2px;
    z-index: 0;
  }
  img {
    vertical-align: middle;
  }
  .icon-up {
    width: 27px;
    height: 27px;
    background: #404040;
    border-radius: 50px;
    position: absolute;
    top: -7px;
    box-shadow: 0px -8px 8px -8px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px -8px 8px -8px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px -8px 8px -8px rgba(0, 0, 0, 0.25);
    .pi {
      font-size: 18px;
      color: #ffffff;
    }
  }
}
</style>
