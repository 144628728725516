<template>
  <div class="grid">
    <div class="xl:col-12 md:col-12 text-center">
      <!--<p
        class="
          text-3xl
          md:text-2xl
          lg:text-auto
          title-color
          font-bold
          mt-2
        "
      >
        ¿Desea obtener la notificación de Pagando?
      </p> -->
      <!-- Pagando -->
      <!--<div class="xl:col-12 md:col-12 px-0 py-0">
        <button
          type="button"
          class="button-get-ticket m-1 email relative"
          :class="{'button--active': sendPagando}"
          @click="setSendPagando()"
        >
          <div class="flex">
            <img src="layout/images/logo-pagando.svg" class="image-ldo" />
            <i
              class="pi pi-check-circle check"
              v-show="sendPagando"
            >
            </i>
          </div>
        </button>
      </div>-->

      <!-- <hr />

      <p
        class="
          text-3xl
          md:text-2xl
          lg:text-auto
          title-color
          font-bold
          mt-2
          mb-6
        "
      >
        ¿De qué servicio desea obtener la notificación?
      </p> -->
      <!-- Servicios/Juegos -->
      <!--div class="xl:col-12 md:col-12 px-0 py-0">
        <div class="grid">
            <div
              class="xl:col-6 md:col-6 px-0 py-0"
              v-for="service in services"
              :key="service.id"
            >
              <button
                type="button"
                class="button-get-ticket m-1 email relative"
                :class="{'button--active': service.active}"
                @click="service.active = !service.active"
              >
                <img :src="service.logo_url" />
                <i
                  class="pi pi-check-circle check"
                  v-show="service.active"
                >
                </i>
              </button>
            </div>
        </div>
      </div-->

      <!--<hr />-->

      <p class="text-3xl md:text-2xl lg:text-auto title-color font-bold mt-2">
        ¿Cómo desea obtener su ticket?
      </p>
      <div class="flex justify-content-center">
        <ButtonPrintTicket :serial="payorder_serial" ref="button_print" />
      </div>
      <div
        v-for="(groupTypeNotifications, index) in getGroupTypeNotifications"
        :key="index"
        class="xl:col-12 md:col-12 px-0 py-0"
      >
        <button
          v-for="typeNotification in groupTypeNotifications"
          type="button"
          :class="`button-get-ticket m-1 ${typeNotification.name} relative ${
            sendNotificationOptions.includes(typeNotification.id)
              ? 'button--active'
              : ''
          }`"
          @click="toggleSendNotificationOption(typeNotification.id)"
          :key="typeNotification.id"
        >
          <div class="flex">
            <div class="col-4 p-1">
              <i
                :class="`pi pi-${iconsTypeNotification[typeNotification.name]}`"
                style="font-size: 4rem"
              ></i>
              <i
                class="pi pi-check-circle check"
                v-show="sendNotificationOptions.includes(typeNotification.id)"
              >
              </i>
            </div>
            <div class="col-8 p-1">
              <p
                class="title-get-ticket text-left text-3xl md:text-2xl font-bold m-0"
              >
                {{ getNameTypeNotification(typeNotification.name) }}
              </p>
              <p class="font-bold text-left">
                {{ getValueNotification(typeNotification.name) }}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import ButtonPrintTicket from "@/components/ticketoffice/buttons/ButtonPrintTicket.vue";

export default {
  props: {
    payorder_serial: {
      type: String,
      default: "",
    },
  },
  components: {
    ButtonPrintTicket,
  },
  data() {
    return {
      iconsTypeNotification: {
        sms: "comments",
        telegram: "telegram",
        whatsapp: "whatsapp",
        mail: "envelope",
      },
    };
  },
  methods: {
    ...mapMutations("notification", [
      "toggleSendNotificationOption",
      "setSendPagando",
    ]),
    getValueNotification(name) {
      switch (name) {
        case "sms":
          return this.phonePrimary;
        case "telegram":
          return this.phoneTelegram;
        case "whatsapp":
          return this.phoneWhatsapp;
        case "mail":
          return this.client.fk_contact.email
            ? this.client.fk_contact.email
            : "";
      }
    },
    getNameTypeNotification(name) {
      return name === "sms"
        ? "SMS"
        : name === "mail"
        ? "Email"
        : name[0].toUpperCase() + name.substring(1);
    },
  },
  computed: {
    ...mapGetters("notification", ["getTypeNotifications"]),
    ...mapState("notification", [
      "sendNotificationOptions",
      "sendPagando",
      "typeNotifications",
      "client",
      "services",
    ]),
    ...mapState("ticketoffice", ["is_processing"]),
    phonePrimary() {
      const activeClient = this.client;
      let phone = "";

      if (activeClient.fk_contact && activeClient.fk_contact.phone_number) {
        phone = activeClient.fk_contact.phone_number;
      }

      return phone;
    },
    phoneWhatsapp() {
      const activeClient = this.client;
      let phone = this.phonePrimary;

      if (activeClient.fk_contact && activeClient.fk_contact.whatsapp) {
        phone = activeClient.fk_contact.whatsapp;
      }

      return phone;
    },
    phoneTelegram() {
      const activeClient = this.client;
      let phone = this.phonePrimary;

      if (activeClient.fk_contact && activeClient.fk_contact.telegram) {
        phone = activeClient.fk_contact.telegram;
      }

      return phone;
    },
    getGroupTypeNotifications() {
      let groups = [];

      // groups.push(this.typeNotifications.filter(item => ['sms', 'mail'].includes(item.name) && this.getValueNotification(item.name).length > 0));
      groups.push(
        this.typeNotifications.filter(
          (item) =>
            ["telegram", "whatsapp"].includes(item.name) &&
            this.getValueNotification(item.name).length > 0
        )
      );

      return groups;
    },
  },
  created() {
    this.setSendPagando();
  },
};
</script>

<style scoped>
.check {
  top: 5px;
  right: 5px;
  position: absolute;
}

.image-ldo {
  width: 16rem;
}

:deep(.button-get-ticket) {
  min-width: 65%;
  width: 20rem;
  padding: 0.5em;
  border-radius: 1rem;
  border: 0rem;
  cursor: pointer;
  background-color: buttonface;
}

.button-get-ticket:disabled,
.button-get-ticket[disabled] {
  pointer-events: none;
}

.sms:hover {
  background: #196fe1;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.mail:hover,
.button--active {
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.whatsapp:hover {
  background: #00e676;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.telegram:hover {
  background: #25a0e1;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 768px) {
  :deep(.button-get-ticket) {
    width: 20rem;
    height: 6rem;
    border-radius: 1rem;
    border: 0rem;
    cursor: pointer;
    background-color: buttonface;
  }
}

.p-splitbutton.print {
  justify-content: space-between;
}
:deep(.p-splitbutton.print .p-button.p-splitbutton-menubutton) {
  background-color: transparent !important;
  border-left: #ccc solid 1px;
  color: #000;
}
:deep(.p-splitbutton.print:hover .p-button.p-splitbutton-menubutton) {
  color: #fff;
}
:deep(.print:hover) {
  background: #000;
  color: #fff;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
