<template>
  <Header />              
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen overflow-hidden md:mt-8">
      <div class="flex justify-content-center md:my-8">
        <div class="card col-11 mb-3">
          <div class="h-full w-full m-0 pt-7 pb-4 px-4" style="border-radius:14px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
            <div class="grid flex flex-column mb-3">
              <h1 class="text-900 font-bold mb-6 text-center">TÉRMINOS Y CONDICIONES PARA KIOSQO</h1>
              <p class="text-600">Este Contrato de Términos y Condiciones (en adelante, "el Contrato") establece los términos y condiciones que rigen el uso del sitio web Kiosqo, propiedad de TECFIN212, C.A. con domicilio en la ciudad de Caracas, República Bolivariana de Venezuela, que funciona como un mercado digital donde se ofrecen múltiples opciones de servicios. además de la venta de juegos de lotería.</p>
              <p class="text-600">Al utilizar el sitio web Kiosqo, el <b>usuario</b> acepta los términos y condiciones establecidos por TECFIN212, C.A..</p>
            </div>
            <div class="grid flex flex-column mb-3">
              <p class="text-900 text-2xl font-bold text-center">I. INTRODUCCIÓN</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">1.1 Descripción del sitio web y su propósito</p>
              <p class="text-600">Kiosqo es un sitio web que funciona como un mercado digital orientado a servicios y juegos de lotería. El propósito de Kiosqo es proporcionar a los usuarios una plataforma para comprar y vender servicios y participar en juegos de lotería.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">1.2 Definición de términos clave:</p>
              <p class="text-600"><b>Kiosqo:</b> se refiere al sitio web y plataforma de mercado digital propiedad de TECFIN212, C.A.</p>
              <p class="text-600"><b>Usuario:</b> se refiere a cualquier persona que acceda y utilice el sitio web Kiosqo.</p>
            </div>
            <div class="grid flex flex-column mb-3">
              <p class="text-900 text-2xl font-bold text-center mt-3">II. USO DEL SITIO WEB</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">2.2 Responsabilidades del usuario</p>
              <p class="text-600"><b>EL USUARIO</b> es responsable de mantener la confidencialidad de su información de inicio de sesión y de todas las actividades que ocurran en su cuenta de Kiosqo.</p>
              <p class="text-600"><b>EL USUARIO</b> se compromete a utilizar el sitio web Kiosqo de acuerdo con la ley y los términos establecidos en este Contrato.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">2.3 Consecuencias del incumplimiento:</p>
              <p class="text-600">En caso de que se detecte que <b>EL USUARIO</b> no cumple con alguno de los requisitos mencionados anteriormente, el acceso y uso de Kiosqo será denegado. Además, cualquier transacción o contrato realizado por un usuario que no cumpla con los requisitos, será considerado nulo y sin efecto legal.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">2.2 Responsabilidades del usuario</p>
              <p class="text-600"><b>EL USUARIO</b> acepta no utilizar el sitio web Kiosqo para actividades ilegales, fraudulentas o que violen los derechos de terceros.</p>
              <p class="text-600"><b>EL USUARIO</b> se compromete a no publicar contenido difamatorio, ofensivo o inapropiado en el sitio web Kiosqo.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">2.5 Propiedad intelectual</p>
              <p class="text-600">Todos los derechos de propiedad intelectual relacionados con el sitio web Kiosqo, incluyendo, pero no limitándose a, los derechos de autor, marcas comerciales, patentes, secretos comerciales, nombres comerciales, diseños y modelos, son propiedad exclusiva de TECFIN212, C.A.</p>
              <p class="text-600"><b>Uso del sitio web:</b> El usuario reconoce y acepta que el uso de Kiosqo no le otorga ningún derecho de propiedad intelectual sobre el mismo. El usuario se compromete a utilizar el sitio web únicamente para fines legales y de acuerdo con los términos y condiciones establecidos en este contrato.</p>
              <p class="text-600"><b>Restricciones de uso:</b> El usuario se compromete a no reproducir, duplicar, copiar, vender, revender ni explotar con ningún fin comercial ninguna parte del sitio web Kiosqo sin la autorización previa y por escrito de TECFIN212, C.A.</p>
              <p class="text-600"><b>Propiedad de los contenidos: </b> Todos los contenidos del sitio web Kiosqo, incluyendo, pero no limitándose a, textos, gráficos, imágenes, fotografías, videos, música, software, códigos y otros materiales, son propiedad exclusiva de TECFIN212, C.A. y están protegidos por las leyes de propiedad intelectual aplicables.</p>
              <p class="text-600"><b>Derechos de autor:</b> Todos los derechos de autor sobre los contenidos del sitio web Kiosqo son propiedad exclusiva de TECFIN212, C.A. y están protegidos por las leyes de propiedad intelectual aplicables.</p>
              <p class="text-600"><b>Marcas comerciales: </b> Todas las marcas comerciales, marcas de servicio, nombres comerciales y logotipos utilizados en el sitio web Kiosqo son propiedad exclusiva de TECFIN212, C.A. y están protegidos por las leyes de propiedad intelectual aplicables.</p>
              <p class="text-600"><b>Uso de los contenidos:</b> El usuario reconoce y acepta que el uso de los contenidos del sitio web Kiosqo está sujeto a las restricciones establecidas en este contrato y en las leyes de propiedad intelectual aplicables.</p>
            </div>
            <div class="grid flex flex-column mb-3">
              <p class="text-900 text-2xl font-bold text-center mt-3">III. SERVICIOS Y JUEGOS DE LOTERÍA</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">3.1 Descripción de los servicios y juegos de lotería ofrecidos</p>
              <p class="text-600">Kiosqo ofrece una amplia variedad de servicios proporcionados por vendedores registrados en la plataforma. Kiosqo también ofrece juegos de lotería en los que los usuarios pueden participar para tener la oportunidad de ganar premios.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">3.2 Proceso de compra y pago</p>
              <p class="text-600">Para comprar servicios o participar en juegos de lotería en Kiosqo, los usuarios deben acceder a su wallet y realizar el pago correspondiente.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">3.3 Cancelaciones y reembolsos</p>
              <p class="text-600">Las cancelaciones y reembolsos de servicios y juegos de lotería están sujetos a las políticas establecidas por los vendedores y Kiosqo no se hace responsable de dichas transacciones.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">3.4 Responsabilidades del vendedor</p>
              <p class="text-600"><b>Servicios de calidad:</b> Las cancelaciones y reembolsos de servicios y juegos de lotería están sujetos a las políticas establecidas por los vendedores y Kiosqo no se hace responsable de dichas transacciones.</p>
              <p class="text-600"><b>Cumplimiento de leyes y regulaciones:</b> El usuario reconoce que es su responsabilidad cumplir con todas las leyes y regulaciones aplicables al utilizar el sitio web Kiosqo. El vendedor no será responsable de ninguna violación de la ley por parte del usuario.</p>
              <p class="text-600"><b>Limitación de responsabilidad:</b> El vendedor no será responsable de ningún daño directo, indirecto, incidental, especial o consecuente que surja del uso del sitio web Kiosqo o de los productos y servicios ofrecidos en él.</p>
              <p class="text-600"><b>Resolución de conflictos:</b> Cualquier conflicto que surja entre el vendedor y el usuario se resolverá mediante negociaciones de buena fe. Si no se llega a un acuerdo, el conflicto se resolverá mediante arbitraje de conformidad con las leyes y regulaciones aplicables.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">3.5 Responsabilidades del comprador</p>
              <p class="text-600">Los compradores son responsables de cumplir con todas las leyes y regulaciones aplicables al utilizar los servicios y participar en juegos de lotería en Kiosqo. En particular, el comprador se compromete a: no utilizar los servicios y participar en juegos de lotería en Kiosqo con fines ilegales o fraudulentos, no utilizar los servicios y participar en juegos de lotería en Kiosqo para acosar, amenazar, difamar, ofender o perjudicar a terceros, no utilizar los servicios y participar en juegos de lotería en Kiosqo para infringir los derechos de propiedad intelectual de terceros, no utilizar los servicios y participar en juegos de lotería en Kiosqo para enviar correo no deseado o spam, no utilizar los servicios y participar en juegos de lotería en Kiosqo para introducir virus, troyanos, gusanos u otros programas maliciosos y a no utilizar los servicios y participar en juegos de lotería en Kiosqo para realizar actividades que puedan dañar, sobrecargar o afectar negativamente la calidad de los servicios.</p>
            </div>
            <div class="grid flex flex-column mb-3">
              <p class="text-900 text-2xl font-bold text-center mt-3">IV. PRIVACIDAD Y SEGURIDAD</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">4.1 Política de privacidad</p>
              <p class="text-600">Kiosqo se compromete a proteger la privacidad de los usuarios y a utilizar la información personal de acuerdo con su Política de Privacidad.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">4.2 Protección de datos personales</p>
              <p class="text-600">Kiosqo recopila y utiliza los datos personales de los usuarios de acuerdo con su Política de Privacidad y las leyes de protección de datos aplicables.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">4.3 Seguridad del sitio web</p>
              <p class="text-600">Kiosqo implementa medidas de seguridad para proteger la información de los usuarios y garantizar la integridad del sitio web.</p>
            </div>
            <div class="grid flex flex-column mb-3">
              <p class="text-900 text-2xl font-bold text-center mt-3">V. RESPONSABILIDAD Y EXENCIÓN DE RESPONSABILIDAD</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">5.1 Limitación de responsabilidad</p>
              <p class="text-600">Kiosqo no se hace responsable del contenido publicado por terceros en el sitio web, incluyendo opiniones, comentarios o información inexacta.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">5.3 Indemnización</p>
              <p class="text-600"><b>EL USUARIO</b> acepta indemnizar y eximir de responsabilidad a Kiosqo por cualquier reclamo, demanda o daño que surja de su uso del sitio web.</p>
            </div>
            <div class="grid flex flex-column mb-3">
              <p class="text-900 text-2xl font-bold text-center mt-3">VI. DISPUTAS Y RESOLUCIÓN DE CONFLICTOS</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">6.1 Procedimientos de resolución de conflictos</p>
              <p class="text-600">Cualquier disputa o conflicto relacionado con el uso del sitio web Kiosqo se resolverá mediante un proceso de mediación.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">6.2 Jurisdicción y ley aplicable</p>
              <p class="text-600">Si la mediación no resuelve la disputa o conflicto, se resolverá mediante arbitraje en Caracas, Venezuela, de acuerdo con las leyes vigentes en dicho lugar.</p>
            </div>
            <div class="grid flex flex-column mb-3">
              <p class="text-900 text-2xl font-bold text-center mt-3">VII. MODIFICACIONES Y TERMINACIÓN</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">7.1 Modificaciones a los términos y condiciones</p>
              <p class="text-600">TECFIN212, C.A. se reserva el derecho de modificar o actualizar estos Términos y Condiciones en cualquier momento. Los cambios entrarán en vigencia inmediatamente después de su publicación en el sitio web Kiosco.Cualquier disputa o conflicto relacionado con estos Términos y Condiciones, se someterá a la jurisdicción exclusiva de los tribunales del lugar en que se encuentra el usuario.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">7.2 Terminación del acuerdo</p>
              <p class="text-600">TECFIN212, C.A. se reserva el derecho de terminar su acceso al sitio web Kiosqo en cualquier momento, sin previo aviso.</p>
            </div>
            <div class="grid flex flex-column mb-3">
              <p class="text-900 text-2xl font-bold text-center mt-3">VIII. DISPOSICIONES GENERALES</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">8.1 Acuerdo completo</p>
              <p class="text-600">Estos Términos y Condiciones constituyen el acuerdo completo entre EL USUARIO y TECFIN212, C.A. con respecto al uso del sitio web Kiosqo.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">8.2 Renuncia</p>
              <p class="text-600">Si alguna disposición de estos Términos y Condiciones se considera inválida o inaplicable, dicha disposición se eliminará y las disposiciones restantes seguirán siendo válidas y aplicables.</p>
            </div>
            <div class="grid flex flex-column my-3">
              <p class="text-900 font-bold m-0">8.3 Jurisdicción</p>
              <p class="text-600">Los presentes términos y condiciones se rigen en su totalidad por la normativa vigente aplicable en la República Bolivariana de Venezuela en materia de loterías, apuestas y juegos lícitos. Cualquier controversia que se derive por el incumplimiento o inobservancia de las presentes condiciones, serán dirimidas por los Tribunales de la República Bolivariana de Venezuela, a cuya jurisdicción se someterán todos los usuarios que acepten los Términos y Condiciones, renunciando en este acto a cualquier otra jurisdicción que pudiere corresponderle por razón de su domicilio presente o futuro, o cualquier otra causa.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  <Footer />
</template>

<script>
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

export default {
  components: {
    Header, Footer,
  },
};
</script>
