<template>
  <div class="printContent ticket-wrapper p-2" id="printContent">
    <div class="card-client">
      <div class="grid">
        <div class="col-6 sm:col-7 flex-order-0">
          Cliente:
          <span class="ml-1"
            ><b
              >{{ activeClient.first_name }} {{ activeClient.last_name }}</b
            ></span
          >
        </div>
        <div class="col-6 sm:col-7 flex-order-1 sm:flex-order-2">
          Cédula:
          <span class="ml-1"
            ><b>{{ activeClient.dni_prefix }}{{ activeClient.dni }}</b></span
          >
        </div>
        <div class="col-6 sm:col-7 flex-order-2 sm:flex-order-4">
          Teléfono:
          <span class="ml-1"
            ><b>{{
              activeClient.fk_contact && activeClient.fk_contact.phone_number
                ? activeClient.fk_contact.phone_number
                : ""
            }}</b></span
          >
        </div>
        <div class="col-6 sm:col-5 sm:relative flex-order-4 sm:flex-order-1">
          <span class="sm:absolute right-0"
            >Fecha:
            <b>{{
              currentPlayData.ticket
                ? formatDate(currentPlayData.ticket.created_at, "DD-MM-YYYY")
                : ""
            }}</b></span
          >
        </div>
        <div class="col-6 sm:col-5 relative flex-order-4 sm:flex-order-3">
          <span class="sm:absolute right-0"
            >Hora:
            <b>{{
              currentPlayData.ticket
                ? formatDate(currentPlayData.ticket.created_at, "LTS")
                : ""
            }}</b></span
          >
        </div>
        <div class="col-6 sm:col-5 sm:relative flex-order-3 sm:flex-order-5">
          <span class="sm:absolute right-0"
            >Serial:
            <b>{{
              currentPlayData.ticket ? currentPlayData.ticket.serial : ""
            }}</b></span
          >
        </div>
      </div>
    </div>
    <div
      class="card-ticket"
      style="min-height: 150px"
      v-bind:style="{
        height: scrollHeight,
        maxHeight: scrollMaxHeight,
      }"
    >
      <div
        class="grid"
        style="height: 100%"
        v-if="currentPlayData && currentPlayData.plays"
      >
        <DataTable
          :key="showTicketPreview"
          :value="currentPlayData.plays"
          :scrollable="this.scroll_visible"
          scrollHeight="flex"
          :virtualScrollerOptions="numItems"
          :showGridlines="false"
          rowGroupMode="subheader"
          groupRowsBy="category_code"
          sortMode="single"
          sortField="category_code"
          :sortOrder="1"
          class="p-datatable-sm ticket-preview"
          :expandableRowGroups="false"
          v-model:expandedRowGroups="expandedRowGroups"
          stripedRows
          rowHover
          :loading="is_processing"
          style="width: 100%; height: 100%"
          v-bind:style="{
            '--ticket__content-scroller':
              currentPlayData.plays.length > 2 || is_printing
                ? ' auto'
                : ' hidden',
          }"
        >
          <template #loading>Procesando. Por favor Espere... </template>
          <template #groupheader="slotProps">
            <div class="grid w-full">
              <div class="col-5 flex align-items-center">
                <img
                  v-if="
                    activeGameList &&
                    activeGameList.find((e) => e.id === slotProps.data.fk_game)
                  "
                  :src="
                    activeGameList.find((e) => e.id === slotProps.data.fk_game)
                      .logo
                  "
                  style="width: auto; height: 35px"
                />
              </div>
              <div class="col-7 pr-0">
                <div class="flex flex-column m-0 p-0">
                  <div class="m-1 mr-0 pr-0 text-right">
                    Fecha:
                    <span
                      ><b>{{
                        formatDate(slotProps.data.event_datetime, "DD-MM-YYYY")
                      }}</b></span
                    >
                  </div>
                  <div class="m-1 mr-0 pr-0 text-right">
                    Sorteo:
                    <span
                      ><b>{{
                        formatDate(slotProps.data.event_datetime, "LT")
                      }}</b></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
          <Column field="category_code" header="" style="width: 1px">
            <template #body="slotProps">
              {{ slotProps.data.category_code }}
            </template>
          </Column>
          <!-- <Column
            field="name"
            header="PRODUCTO/SERVICIO"
            style="min-width: 160px"
          ></Column> -->
          <Column field="name" header="ITEM" style="min-width: 160px"></Column>
          <Column field="price" header="MONTO Bs." style="max-width: 180px">
            <template #body="slotProps">
              {{ Number(slotProps.data.price).toFixed(2) }}
              {{ `(${convert(Number(slotProps.data.price))} VES)` }}
            </template>
          </Column>
          <!--Column header="CANTIDAD" style="max-width: 80px">
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template>
          </Column -->
          <Column style="max-width: 40px" bodyClass="text-right">
            <template #body="slotProps">
              <Button
                icon="pi pi-times"
                class="p-button-danger p-button-text notprintContent"
                @click="deleteOrderItem(slotProps.data.id)"
              />
            </template>
          </Column>
          <template #footer>
            {{
              currentPlayData && currentPlayData.plays
                ? currentPlayData.plays.length +
                  " Item" +
                  (currentPlayData.plays.length == 0 ||
                  currentPlayData.plays.length > 1
                    ? "s"
                    : "") +
                  " Agregado" +
                  (currentPlayData.plays.length == 0 ||
                  currentPlayData.plays.length > 1
                    ? "s"
                    : "")
                : ""
            }}
          </template>
        </DataTable>
      </div>
    </div>
    <div class="card-total">
      <div class="grid">
        <div class="col-5 sm:col-6">Sub-total</div>
        <div class="col-2">BS.</div>
        <div class="col-5 sm:col-4 text-right">
          {{
            currentPlayData &&
            currentPlayData.ticket &&
            !isNaN(currentPlayData.ticket.sub_total)
              ? `${Number(currentPlayData.ticket.sub_total).toFixed(
                  2
                )} (${convert(Number(currentPlayData.ticket.sub_total))} VES)`
              : Number(0).toFixed(2)
          }}
        </div>
      </div>
      <div class="grid ticket-footer">
        <div class="col-5 sm:col-6">Impuesto</div>
        <div class="col-2">0.00%</div>
        <div class="col-5 sm:col-4 text-right">0.00</div>
      </div>
      <div class="grid mt-2 p-0">
        <div class="col-5 sm:col-6"><b>TOTAL A PAGAR</b></div>
        <div class="col-2"><b>BS.</b></div>
        <div class="col-5 sm:col-4 text-right">
          <b>{{
            currentPlayData &&
            currentPlayData.ticket &&
            !isNaN(currentPlayData.ticket.total)
              ? `${Number(currentPlayData.ticket.total).toFixed(2)} (${convert(
                  Number(currentPlayData.ticket.total)
                )} VES)`
              : Number(0).toFixed(2)
          }}</b>
        </div>
      </div>
    </div>
    <div class="card-keyboard mt-2 notPrint">
      <div class="grid">
        <div class="col-12 flex flex-row justify-content-center pt-3 pb-2">
          <Button
            class="w-full keyboard-button px-1 mr-2 text-2xl sm:text-3xl"
            label="ANULAR"
            style="background: #ef3341; color: #fff"
            :disabled="
              is_processing ||
              !(currentPlayData && currentPlayData.plays.length)
            "
            @click="setShowConsultClient(true)"
            v-tooltip.bottom="'Anular Jugada'"
          />
          <!-- <Button
            class="w-full keyboard-button px-2 ml-1 mx-0 text-2xl sm:text-3xl"
            label="CLIENTE"
            style="background: rgb(0, 74, 241); color: #fff"
            :disabled="
              is_processing ||
              !(
                ticketofficeOnLine ||
                ['taquilla'].includes($store.getters['auth/role'])
              )
            "
            @click="setShowConsultClient(true)"
            v-tooltip.bottom="'Establecer Cliente'"
          /> -->
          <Button
            class="w-full keyboard-button px-2 ml-1 text-2xl sm:text-3xl"
            label="PAGAR"
            @click="setPayDialog(true)"
            style="background: #46af3d; color: #fff"
            :disabled="is_processing || !currentPlayData.plays.length"
            v-tooltip.bottom="'Procesar Pago'"
            v-if="showPayButton"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import moment from "moment";
//import * as PrintJS from "print-js";

export default {
  props: {
    scrollHeight: {
      type: String,
      default: "calc(100vh - 200px)",
    },
    scrollMaxHeight: {
      type: String,
      default: "540px",
    },
    showPayButton: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DataTable,
  },
  data() {
    return {
      scroll_visible: true,
      numItems: { itemSize: 1 },
      is_printing: false,
      expandedRowGroups: null,
      is_loaded: 0,
    };
  },
  methods: {
    ...mapActions("ticketoffice", ["cancelPlayOrder", "cancelPlayOrderItem"]),
    ...mapMutations("ticketoffice", [
      "deletePlayOrderItem",
      "clearCurrentPlayData",
      "setShowConsultClient",
      "setPayDialog",
    ]),
    deleteOrderItem(item_id) {
      if (this.currentPlayData.plays.length <= 1) {
        this.cancelPlayOrder(this.currentPlayData.ticket.id).catch((err) => {
          if (err.response)
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              detail: err.response.data.message,
              life: 4000,
            });
        });
      } else
        this.cancelPlayOrderItem({
          order_id: this.currentPlayData.ticket.id,
          id: item_id,
        }).catch((err) => {
          if (err.response)
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              detail: err.response.data.message,
              life: 4000,
            });
        });
    },
    formatDate(date, format, utcToLocal = false) {
      return utcToLocal
        ? moment.utc(date, ["DD/MM/YYYY hh:mm:ss"]).local().format(format)
        : moment(date, ["DD/MM/YYYY hh:mm:ss"]).format(format);
    },
    toPrint() {
      this.$toast.removeAllGroups();
      this.setPayDialog(false);
      this.numItems = null;
      this.is_printing = true;

      setTimeout(() => {
        window.print();
      }, 60);
    },
    convert(amount) {
      return Number(amount * this.monedaOptions[0].exchange_rate).toFixed(2);
    },
  },
  computed: {
    ...mapGetters("ticketoffice", [
      "activeGameList",
      "activeClient",
      "selectedGame",
      "selectedLottery",
      "currentPlayData",
      "currentGameServiceRequest",
      "currentPlayDataPlayList",
    ]),
    ...mapState("ticketoffice", [
      "is_processing",
      "enabledKeyboardInput",
      "ticketofficeOnLine",
      "showTicketPreview",
      "monedaOptions",
    ]),
    currentPlayTotal() {
      if (this.currentPlayData && this.currentPlayData.plays.length)
        return this.currentPlayData.plays.reduce(
          (a, b) => a + Number(b.price),
          0
        );
      else return 0;
    },
  },
};
</script>

<style>
.ticket-preview .p-datatable-thead {
  border-top: solid 1px;
  border-bottom: solid 1px;
}
.p-virtualscroller {
  overflow: var(--ticket__content-scroller) !important;
  min-height: 100%;
}
.p-virtualscroller-content {
  position: relative !important;
  top: 0px !important;
}
.card-ticket {
  height: 51vh;
}
.card-screen {
  height: 12vh;
}
.ticket-wrapper {
  height: 100%;
  min-height: 300px;
}

@media only screen and (max-width: 768px) {
  .ticket-wrapper {
    height: calc(100% - 330px) !important;
  }
  .card-keyboard,
  .card-client,
  .card-ticket,
  .card-total {
    padding: 1rem;
    border-radius: 0.5rem;
  }
  .card-ticket {
    height: 35vh;
  }
  .card-screen {
    height: 65px;
  }
}

/* @media print {
  .notprintContent,
  .p-message,
  .p-toast {
    visibility: hidden;
  }

  .printContent {
    display: visible;
  }

  .ticket-wrapper {
    height: fit-content !important;
    position: fixed;
    top: 0;
    left: 0;
  }

  .notPrint {
    display: none;
  }

  body * {
    visibility: hidden;
    font-family: Consoles;
  }

  body {
    background-color: #ffff;
  }

  #printContent,
  #printContent * {
    visibility: visible;
    border: none !important;
    max-height: none !important;
    font-family: Consoles;
    color: #000;
    font-weight: bold;
  }

  .card-client,
  .card-ticket,
  .card-total,
  .operador-marco {
    margin-bottom: 0.5rem;
    background-color: #ffff !important;
    box-shadow: none !important;
    border: none !important;
  }
  .card-ticket {
    height: fit-content !important;
    overflow: unset !important;
  }
} */

/* scroll size */
.p-virtualscroller::-webkit-scrollbar {
  width: 8px;
}

/* scroll (thumb) de style */
.p-virtualscroller::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.p-virtualscroller::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.p-virtualscroller::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* scroll track styles */
.p-virtualscroller::-webkit-scrollbar-track {
  background: #f3f0f0;
  border-radius: 4px;
}

.p-virtualscroller::-webkit-scrollbar-track:hover,
.p-virtualscroller::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
</style>

<style scoped>
.keyboard-button {
  padding: 5px;
  height: 3.5rem;
}
.card-ticket :deep(.p-rowgroup-header) {
  background-color: #f5f5f5 !important;
}
.ticket-footer {
  border-bottom: solid 1px #000;
}
</style>
