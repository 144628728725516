import HTTP from "@/service/http";
import session from "@/service/session";
import { jwtDecode } from "jwt-decode";

export default {
  namespaced: true,
  state: {
    user: session.getUserData(),
    roles: [],
    user_menu: [],
    ticketoffice_online: false,
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .post("/token", data)
          .then((resp) => {
            commit("auth_success", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    verifyAccessToken({ commit, state }) {
      if (state.user)
        return new Promise((resolve, reject) => {
          HTTP(true)
            .post("token/verify", {
              token: JSON.parse(
                localStorage.getItem(process.env.VUE_APP_SESSION_KEY)
              ).access_token,
            })
            .then((resp) => {
              if (!resp.data.status === "SUCCESS") commit("logout_session");
            })
            .catch((err) => {
              reject(err);
            });
        });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/logout")
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit("logout_session");
          });
      });
    },
    partial_signup(s, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .post("/persons/register/partial", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getRoles({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get("/users/roles")
          .then((resp) => {
            commit("setRolesList", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    auth_success: (state, data) => {
      state.user = {
        email: data.data.email,
        rol: data.data.rol,
        id: data.data.uuid,
      };
      session.saveUserSession({
        user: state.user,
        access_token: data.data.access,
        refresh_token: data.data.refresh,
      });
      
      /* const token_payload = jwtDecode(data.data.access);

      if (token_payload && token_payload.role && token_payload.role === "7L89K5") {
        state.ticketoffice_online = true;
      }
 */
      /* const sesionToken = data.data.access;
      console.log("resp", sesionToken);
      const [header, payload, signature] = sesionToken.split(".");
      console.log("header", header);
      console.log("signature", signature);
      console.log("payload", payload);
      console.log("payload", JSON.stringify(payload));
      var base64Url = sesionToken.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      console.log("hola", JSON.parse(jsonPayload)); */
    },
    logout_session: (state) => {
      state.user = null;
      localStorage.removeItem(process.env.VUE_APP_SESSION_KEY);
      localStorage.removeItem(process.env.VUE_APP_TICKETOFFICE_SESSION_KEY);
    },
    setRolesList: (state, data) => {
      state.roles = data.data;
    },
  },
  getters: {
    user: (state) => {
      return state.user ? state.user : null;
    },
    logged: (state) => {
      return !!state.user;
    },
    user_id: (state) => {
      return state.user ? state.user.id : null;
    },
    role: (state) => {
      return state.user ? state.user.rol : null;
    },
    name: (state) => {
      return state.user ? state.user.email : null;
    },
    email: (state) => {
      return state.user ? state.user.email : null;
    },
    roleList: (state) => {
      return state.roles ? state.roles : [];
    },
    userMenu: (state) => {
      return state.user_menu;
    },
    ticketoffice_online: (state) => {
      const token = JSON.parse(
        localStorage.getItem(process.env.VUE_APP_SESSION_KEY)
      )?.access_token
      const token_payload = token ? jwtDecode(token) : null;

      return state && token_payload && token_payload.role && token_payload.role === "7L89K5"
    },
  },
};
