<template>
  <Dialog
    :modal="true"
    :style="{
      width: '750px',
      maxHeight: '100vh',
      maxWidth: '100vw',
      minHeight: '400px',
    }"
    class="p-fluid dialog-consult"
    @show="onShow"
    @hide="closeModal"
    :closable="true"
    :closeOnEscape="true"
    v-model:visible="getOpenModelConsultPaymentOrders"
  >
    <template #header>
      <span class="modal-title" v-if="!clientPayOrder"
        ><IconSearch /> Consultar órdenes de pago
        <hr
      /></span>
      <span class="modal-title" v-else
        ><icon-notification /> Reenviar
        <hr
      /></span>
    </template>
    <div class="w-full mt-2">
      <form
        class="w-full mt-0"
        autocomplete="off"
        aria-autocomplete="off"
        id="consult-client-pay-orders"
        @submit.prevent="handleSubmit(!v$.$invalid)"
      >
        <div
          class="formgrid grid"
          v-if="!clientPayOrder && this.is_processing_delete == false"
        >
          <div class="field col-3 display-form">
            <div class="p-float-label">
              <Dropdown
                v-model="client.country_id"
                :options="countries"
                optionValue="id"
                optionLabel="name"
                :filter="false"
                :showClear="false"
                class="w-full"
                style="min-height: 47px"
                :class="{
                  'p-invalid':
                    v$.client.country_id &&
                    v$.client.country_id.$invalid &&
                    submitted,
                }"
              >
                <template #value="slotProps">
                  <div
                    class="country-item country-item-value"
                    v-if="slotProps.value"
                  >
                    <img
                      :src="
                        this.countries.find((e) => e.id == slotProps.value)
                          ? this.countries.find((e) => e.id == slotProps.value)
                              .flag
                          : ''
                      "
                    />
                    <div>
                      {{
                        this.countries.find((e) => e.id == slotProps.value)
                          ? this.countries
                              .find((e) => e.id == slotProps.value)
                              .tld.toLocaleUpperCase()
                          : ""
                      }}
                    </div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="country-item">
                    <img :src="slotProps.option.flag" />
                    <div>{{ slotProps.option.tld.toLocaleUpperCase() }}</div>
                  </div>
                </template>
              </Dropdown>
              <label for="profile_client_counry">País *</label>
            </div>
            <small
              v-if="
                v$.client.country_id &&
                ((v$.client.country_id.$invalid && submitted) ||
                  v$.client.country_id.$pending.$response)
              "
              class="p-error"
              >Campo requerido.</small
            >
          </div>
          <div class="field col-9 display-form">
            <div class="p-inputgroup phone-inputgroup">
              <span class="p-float-label">
                <InputText
                  id="client-pay-orders-dni"
                  v-model.trim="v$.client.dni.$model"
                  class="operador-imput"
                  v-bind:value="client.dni"
                  :class="{
                    'p-invalid': v$.client.dni.$invalid && submitted,
                  }"
                  autocomplete="off"
                  aria-autocomplete="off"
                  autofocus
                  @keydown.enter="handleSubmit(!v$.$invalid)"
                  @keypress="restrictChartsNumber($event)"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  maxlength="15"
                />
                <label
                  class="operador-imput-title"
                  for="client-pay-orders-dni"
                  :class="{
                    'p-error': v$.client.dni.$invalid && submitted,
                  }"
                  >Documento de Identidad *</label
                >
              </span>
            </div>
            <small
              v-if="
                (v$.client.dni.$invalid && submitted) ||
                v$.client.dni.$pending.$response
              "
              class="p-error"
              >Ingrese el Número de Identidad.</small
            >
          </div>
          <div class="field col-12 display-form">
            <div class="p-inputgroup phone-inputgroup">
              <span class="p-float-label">
                <InputText
                  id="client-pay-orders-payorder_reference"
                  v-model.trim="v$.client.payorder_reference.$model"
                  class="operador-imput"
                  :class="{
                    'p-invalid':
                      v$.client.payorder_reference.$invalid && submitted,
                  }"
                  autocomplete="off"
                  aria-autocomplete="off"
                  autofocus
                  pattern="[0-9a-zA-Z]+"
                  maxlength="4"
                  @keydown.enter="handleSubmit(!v$.$invalid)"
                />
                <label
                  class="operador-imput-title"
                  for="client-pay-orders-payorder_reference"
                  :class="{
                    'p-error':
                      v$.client.payorder_reference.$invalid && submitted,
                  }"
                  >Últimos 4 dígitos de la orden de pago (Opcional)</label
                >
              </span>
            </div>
          </div>
          <div class="field col-12" v-show="searchDone">
            <DataTable
              ref="dt"
              :value="clientPayOrders"
              data-key="serial"
              :paginator="false"
              :rows="5"
              responsiveLayout="scroll"
              class="p-datatable-sm"
              rowHover
              :loading="is_loading_data"
              style="min-height: 300px"
            >
              <template #loading>
                Cargando datos. Por favor Espere...
              </template>
              <template #empty> Sin Registros...</template>
              <template #header>
                <span class="text-lg p-3"
                  >Ultimas 5 órdenes de pago del cliente</span
                >
              </template>
              <Column
                field="created_at"
                header="Fecha y Hora"
                :sortable="true"
                headerStyle="min-width:150px; text-align: center;"
              >
                <template #body="slotProps">
                  <span class="p-column-title">Fecha y Hora</span>
                  <div class="w-full white-space-normal text-center">
                    {{ formatDate(slotProps.data.created_at, "DD-MM-YYYY")
                    }}<br />{{
                      formatDate(slotProps.data.created_at, "hh:mm a")
                    }}
                  </div>
                </template>
              </Column>
              <Column
                field="serial"
                header="Serial"
                :sortable="true"
                headerStyle="min-width: 6rem; text-align: center;"
              >
                <template #body="slotProps">
                  <span class="p-column-title">Serial</span>
                  {{
                    `${slotProps.data.serial.slice(
                      0,
                      8
                    )}****${slotProps.data.serial.slice(-4)}`
                  }}
                </template>
              </Column>
              <Column
                header="Acción"
                headerStyle="min-width: 8rem; text-align: center;"
              >
                <template #body="slotProps">
                  <span class="p-column-title">Acción</span>
                  <div class="flex flex-wrap justify-content-center w-full">
                    <Button
                      class="my-1 resend-button line-height-2 bg-white w-auto"
                      @click="setClientPayOrder(slotProps.data)"
                    >
                      <i class="pi pi-send"></i>
                      <span class="hidden sm:block">
                        &nbsp; Reenviar notificación</span
                      >
                    </Button>
                    <Button
                      class="delete-button border-none my-1 font-light text-white line-height-2 w-auto ml-3"
                      @click="deleteOrder(slotProps.data)"
                    >
                      <i class="pi pi-trash"></i>
                      <span class="hidden sm:block"> &nbsp; Anular órden</span>
                    </Button>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <SendNotification
          v-if="clientPayOrder"
          :payorder_serial="clientPayOrder.serial"
        />
        <CancelPayOrder
          v-if="this.is_processing_delete == true"
          :gameAndReferences="gameAndReferences"
          @deletePayOrder="deletePayOrder"
        />
      </form>
    </div>

    <template #footer>
      <Button
        :label="clientPayOrder ? 'Cancelar' : 'Aceptar'"
        :icon="clientPayOrder ? 'pi pi-times' : ''"
        :class="clientPayOrder ? 'cancel' : 'confirm'"
        @click="onCancel"
        :disabled="is_processing || is_processing_delete"
      />
      <Button
        v-if="clientPayOrder"
        :label="clientPayOrder ? 'Reenviar notificación' : 'Consultar'"
        form="consult-client-pay-orders"
        type="submit"
        icon="pi pi-check"
        class="main ml-3"
        :loading="is_processing"
        :disabled="
          validateRequiredData || is_processing || is_processing_delete
        "
      />
    </template>
  </Dialog>
</template>

<script>
import HTTP from "@/service/http";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import DataTable from "primevue/datatable";
import moment from "moment";
import SendNotification from "./SendNotification.vue";
import CancelPayOrder from "./CancelPayOrder.vue";
import IconSearch from "@/components/svg/icons/IconSearch.vue";
import IconNotification from "../../svg/icons/IconNotification.vue";

export default {
  components: {
    DataTable,
    SendNotification,
    CancelPayOrder,
    IconSearch,
    IconNotification,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      is_loading_data: true,
      is_processing: false,
      is_processing_delete: false,
      searchDone: false,
      submitted: false,
      client: {},
      countries: [],
      clientPayOrders: [],
      clientPayOrder: null,
      gameAndReferences: [],
      gameAndReference: null,
    };
  },
  created() {
    this.getDirectionsCountries();
    this.getListTypeNotifications();
  },
  methods: {
    ...mapActions("ticketoffice", ["cancelPlayOrder", "consultClient"]),
    ...mapActions("notification", ["getListTypeNotifications"]),
    ...mapMutations("ticketoffice", [
      "setActiveClient",
      "clearCurrentPlayData",
      "saveTicketofficeSession",
      "setClientDialog",
      "setToggleTicketPreview",
      "setPayDialog",
      "setModelConsultPaymentOrders",
    ]),
    ...mapMutations("notification", [
      "setClient",
      "setServices",
      "setSendNotificationOptions",
      "resetSendPagando",
    ]),
    formatDate(date, format) {
      return moment(date).format(format);
    },
    restrictCharts($event) {
      if (
        [32, 39, 193, 201, 205].includes($event.charCode) ||
        ($event.charCode >= 65 && $event.charCode <= 90) ||
        ($event.charCode >= 97 && $event.charCode <= 122) ||
        ($event.charCode >= 129 && $event.charCode <= 160) ||
        ($event.charCode >= 208 && $event.charCode <= 250)
      ) {
        return true;
      }

      $event.preventDefault();
    },
    restrictChartsNumber($event) {
      if ($event.charCode >= 48 && $event.charCode <= 57) {
        return true;
      }
      $event.preventDefault();
    },
    onShow() {
      this.client = {};
      this.clientPayOrder = null;
      this.submitted = false;
      this.is_processing = false;
      this.client.dni_code = "V";
      this.client.dni = this.activeClient.dni;
      this.setInputFocus("client-pay-orders-dni");
      this.setCountryUser();
      this.consultClientPayOrders();
    },
    closeModal() {
      this.searchDone = false;
      this.clientPayOrders = [];
      this.clientPayOrder = null;
      this.setModelConsultPaymentOrders(false);
    },
    onCancel() {
      if (this.clientPayOrder) {
        this.clientPayOrder = null;

        return;
      }

      this.searchDone = false;
      this.clientPayOrders = [];
      this.clientPayOrder = null;
      this.setInputFocus("client-pay-orders-dni");

      this.setModelConsultPaymentOrders(false);
    },
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (this.clientPayOrder) {
        this.resendNotification();
        return;
      }

      if (!isFormValid) {
        this.$nextTick(() => this.findFirstError());
        return;
      }

      this.is_processing = true;

      this.consultClientPayOrders();
    },
    findFirstError() {},
    consultClientPayOrders() {
      this.searchDone = true;
      this.is_loading_data = true;
      this.$store
        .dispatch("ticketoffice/consultClientPayOrdersData", this.client)
        .then((resp) => {
          this.clientPayOrders = resp.data.data;

          this.$store
            .dispatch("ticketoffice/consultClient", { dni: this.client.dni })
            .then((resp) => {
              this.setClient(resp.data.data);
            });
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: this.getFirstError(err.response.data.errors),
            life: 4000,
          });
        })
        .finally(() => {
          this.is_processing = false;
          this.is_loading_data = false;
          this.submitted = false;
        });
    },
    resendNotification() {
      let services_id = [];
      this.services.forEach((service) => {
        if (service.active) {
          services_id.push(service.id);
        }
      });

      this.is_processing = true;
      this.$store
        .dispatch("ticketoffice/resendNotification", {
          payorder_serial: this.clientPayOrder.serial,
          notify_type_id: this.sendNotificationOptions,
          services_id,
          send_pagando: this.sendPagando,
        })
        .then((resp) => {
          this.clientPayOrders = resp.data.data;
          this.$toast.add({
            severity: "success",
            detail: "Reenvío de notificación con éxito.",
            life: 4000,
          });
          this.closeModal();
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: this.getFirstError(err.response.data.errors),
            life: 4000,
          });
        })
        .finally(() => {
          this.is_processing = false;
          this.submitted = false;
        });
    },
    setDropdownFocus(field_name) {
      setTimeout(() => {
        let input = document.getElementById(field_name);
        if (input) {
          input.getElementsByTagName("input")[1].focus();
        }
      }, 60);
    },
    setInputFocus(field_name) {
      setTimeout(() => {
        let input = document.getElementById(field_name);
        if (input) {
          input.focus();
          input.scrollIntoView(true);
        }
      }, 60);
    },
    getDirectionsCountries() {
      HTTP(true)
        .get("/directions/countries")
        .then((resp) => {
          this.countries = resp.data.data.map((e) => {
            return {
              id: e.id,
              name: e.name,
              flag: e.flag,
              callingCode: e.phonecod,
              tld: e.tld,
            };
          });
          this.setCountryUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setCountryUser() {
      const default_country = this.countries.find(
        (e) => e.tld.toLowerCase() === this.country_code.toLowerCase()
      );

      if (default_country) {
        this.client.country_id = default_country.id;
      }
    },
    showSendNotification() {
      this.clientPayOrder = null;
      this.setPayDialog(false);
    },
    setClientPayOrder(data) {
      this.clientPayOrder = data;
      this.resetSendPagando();
      this.setSendNotificationOptions([]);
      this.setServices(
        data.services.map((service) => {
          service.active = false;

          return service;
        })
      );
    },
    deleteOrder(data) {
      HTTP()
        .get(`/payorders/product-order-list/${data.id}`)
        .then((resp) => {
          this.is_processing_delete = true;
          this.gameAndReferences = {
            services: resp.data.data.services,
            id: data.serial,
          };
          this.$toast.add({
            severity: "success",
            detail: "",
            life: 4000,
          });
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: this.getFirstError(err.response.data.errors),
            life: 4000,
          });
        });
    },
    deletePayOrder(data) {
      let payorder_reference = data.game;
      let id = data.id;
      HTTP()
        .delete(`/payorders/product-order-list/${id}`, {
          data: { payorder_reference },
        })
        .then((resp) => {
          console.log(resp);
          this.$toast.add({
            severity: "success",
            detail: "se ha cancelado la orden de pago",
            life: 4000,
          });
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: this.getFirstError(err.response.data.errors),
            life: 4000,
          });
        })
        .finally(() => {
          this.is_processing_delete = false;
        });
    },
  },
  computed: {
    ...mapGetters("ticketoffice", ["activeClient", "currentPlayData"]),
    ...mapState("ticketoffice", ["ticketofficeOnLine"]),
    ...mapState("configurations", ["country_code"]),
    ...mapState("notification", [
      "sendNotificationOptions",
      "services",
      "sendPagando",
    ]),
    validateRequiredData() {
      if (this.clientPayOrder) {
        if (this.sendNotificationOptions.length === 0) return true;

        if (
          this.services.length > 0 &&
          this.services.filter((service) => service.active).length === 0 &&
          !this.sendPagando
        )
          return true;
      }

      return false;
    },
    getOpenModelConsultPaymentOrders: {
      get() {
        return this.$store.getters[
          "ticketoffice/getOpenModelConsultPaymentOrders"
        ];
      },
      set(value) {
        if (!value) this.closeModal();
      },
    },
  },
  validations() {
    return {
      client: {
        dni: {
          required,
        },
        country_id: {
          required,
        },
        payorder_reference: {},
      },
    };
  },
};
</script>

<style scoped lang="scss">
.delete-button:hover {
  background-color: #ff824a !important;
}
.resend-button {
  border: 1px solid #cc195a;
  color: #cc195a;
  &:hover {
    background-color: #ff824a !important;
    border-color: #ff824a;
    color: #fff;
  }
  .pi {
    font-size: 16px;
    font-weight: 900;
  }
}
.phone-inputgroup {
  .p-inputgroup-addon {
    padding: 0;
    width: 145px;
  }
  .p-dropdown {
    border: 0px;
  }

  .p-inputgroup-addon.p-error {
    border-color: #b00020;
  }
  .p-dropdown :deep(.p-dropdown-trigger) {
    width: 1.8rem !important;
  }
}
.country-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.country-item {
  img {
    width: 17px;
    margin-right: 0.5rem;
  }
}

.display-form {
  display: none;
}
.p-dialog-content {
  min-height: 350px;
}
:deep(.p-column-title) {
  width: 100% !important;
}
</style>
