<template>
  <div class="style-background">
    <div
      class="flex signup-background align-content-center justify-content-center flex-wrap h-screen signup-background"
    >
      <div
        class="login-marco-padding col-12 mt-5 xl:mt-0 text-center logo-login"
      >
        <a href="#" @click.stop.prevent="goLandingPage">
          <img
            :src="'layout/images/logo-kiosqo.svg'"
            alt="logo"
            class="login-marco-logo mb-5 width-login"
          />
        </a>
      </div>
      <div
        class="flex align-items-center justify-content-center background-marco-signup font-bold border-round-2xl p-6"
      >
        <div
          class="col-12 flex align-items-center justify-content-center flex-column"
        >
          <div class="flex flex-column align-items-center mb-2">
            <h4 class="text-center text-300">
              Completa estos pasos para registrar una cuenta en Kiosqo
            </h4>
            <span
              v-if="dni_user_active"
              class="text-300 style-message text-center"
            >
              Ya tienes una cuenta de usuario activa en Kiosqo!
            </span>
            <br />
          </div>
          <div
            class="col-12 md:col-8 lg:col-8 pb-3 mb-6 border-700 border-bottom-1"
          >
            <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
              <!-- Step 1 checking user DNI -->
              <div v-if="step1_check_dni && !dni_user_active" class="p-fluid">
                <p class="text-center text-300 mb-4">
                  Ingresa tu número de cédula
                </p>
                <div class="field">
                  <div class="w-full p-float-label p-input-icon-left text-300">
                    <i class="pi pi-user text-300" />
                    <InputText
                      class="login-inputtext text-300"
                      id="cedula"
                      type="text"
                      v-model="cedula"
                      :disable="checkingDni"
                    />
                    <label class="login-float font-normal text-300" for="cedula"
                      >Cedula *</label
                    >
                  </div>
                  <div class="flex justify-content-center">
                    <Button
                      type="submit"
                      class="button-fondo text-2xl mt-3"
                      label="Siguiente"
                      :disabled="!cedula || checkingDni"
                      :loading="checkingDni"
                    />
                  </div>
                </div>
              </div>
              <!-- Step 2 partial user register form -->
              <div v-if="!dni_used && step2_partial_register_form">
                <p class="text-center font-normal text-300 mb-2">
                  Ingresa tu nombre y número de teléfono móvil personal
                </p>
                <!--  -->
                <div class="field">
                  <Dropdown
                    v-model="stateCountry"
                    :options="stateCountrys"
                    optionLabel="name"
                    placeholder="Selecciona tu estado"
                    class="w-full login-inputtext dropdown-state-country"
                  />
                </div>

                <!--  -->

                <div class="field">
                  <div class="w-full p-float-label p-input-icon-left text-300">
                    <i class="pi pi-user text-300" />
                    <InputText
                      class="login-inputtext text-300"
                      :disabled="dni_used"
                      id="user_name"
                      type="text"
                      v-model="person_names"
                    />
                    <label
                      class="login-float font-normal text-300"
                      for="user_name"
                      >Nombre *</label
                    >
                  </div>
                </div>

                <div class="field">
                  <div class="w-full p-float-label p-input-icon-left text-300">
                    <i class="pi pi-user text-300" />
                    <InputText
                      class="login-inputtext text-300"
                      :disabled="dni_used"
                      id="user_lastname"
                      type="text"
                      v-model="person_lastnames"
                    />
                    <label
                      class="login-float font-normal text-300"
                      for="user_lastname"
                      >Apellido *</label
                    >
                  </div>
                </div>

                <div class="field login-inputtext text-300">
                  <div
                    class="login-inputtext text-300"
                    style="position: relative"
                  >
                    <label class="login-float font-normal text-300"
                      >Teléfono móvil *</label
                    >
                    <MazPhoneNumberInput
                      class="login-inputtext font-normal text-300"
                      v-model="phone_number"
                      show-code-on-list
                      color="info"
                      autocomplete="off"
                      :fetch-country="false"
                      default-country-code="VE"
                      :translations="phone_translations"
                      @update="updatePhoneNumber"
                    />
                    <small
                      v-if="!phone_valid && submitted"
                      class="p-error text-300"
                      >Ingrese un número de teléfono válido.</small
                    >
                  </div>
                </div>
                <div class="field-checkbox font-normal text-300">
                  <Checkbox
                    id="accept"
                    name="accept"
                    :binary="true"
                    v-model="accept"
                    :class="{ 'p-invalid': v$.accept.$invalid && submitted }"
                  />
                  <label
                    for="accept"
                    :class="{ 'p-error': v$.accept.$invalid && submitted }"
                    >Estoy de acuerdo con los
                    <b>Términos y condiciones </b> *</label
                  >
                </div>

                <Button
                  type="submit"
                  label="Regístrate"
                  class="button-fondo text-2xl mt-2"
                  :loading="loading"
                  :disabled="v$.$invalid"
                />
              </div>
              <!-- Step 3 complete user register form -->
              <div v-if="dni_used && step3_complete_register_form">
                <p class="text-center font-normal text-300 mb-2">
                  Ingresa tu correo electrónico y contraseña, para completar el
                  registro.
                </p>
                <div class="field">
                  <div class="w-full p-float-label p-input-icon-left text-300">
                    <i class="pi pi-envelope" />
                    <InputText
                      :disabled="loading"
                      id="email"
                      v-model="v$.email.$model"
                      :class="{
                        'p-invalid login-float font-normal text-300':
                          v$.email.$invalid && submitted,
                      }"
                      aria-describedby="email-error"
                    />
                    <label
                      for="email"
                      :class="{ 'p-error': v$.email.$invalid && submitted }"
                      >Correo electrónico *</label
                    >
                  </div>
                  <span v-if="v$.email.$error && submitted">
                    <span
                      id="email-error"
                      v-for="(error, index) of v$.email.$errors"
                      :key="index"
                    >
                      <small class="p-error">{{ error.$message }}</small>
                    </span>
                  </span>
                  <small
                    v-else-if="
                      (v$.email.$invalid && submitted) ||
                      v$.email.$pending.$response
                    "
                    class="p-error"
                    >{{
                      v$.email.required.$message.replace("Value", "Email")
                    }}</small
                  >
                </div>
                <div class="field">
                  <div class="w-full p-float-label p-input-icon-left text-300">
                    <Password
                      id="password"
                      v-model="v$.password.$model"
                      :class="{
                        'p-invalid': v$.password.$invalid && submitted,
                      }"
                      toggleMask
                    >
                      <template #header>
                        <h6>Elije una contraseña</h6>
                      </template>
                      <template #footer="sp">
                        {{ sp.level }}
                        <Divider />
                        <p class="mt-2">Sugerencias</p>
                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                          <li>Al menos una minúscula</li>
                          <li>Al menos una mayúscula</li>
                          <li>Al menos un número</li>
                          <li>Mínimo 8 caracteres</li>
                        </ul>
                      </template>
                    </Password>
                    <label
                      class="login-float font-normal text-300"
                      for="password"
                      :class="{ 'p-error': v$.password.$invalid && submitted }"
                      >Contraseña *</label
                    >
                  </div>
                  <small
                    v-if="
                      (v$.password.$invalid && submitted) ||
                      v$.password.$pending.$response
                    "
                    class="p-error"
                    >{{
                      v$.password.required.$message.replace("Value", "Password")
                    }}</small
                  >
                </div>
                <div class="field">
                  <div class="w-full p-float-label p-input-icon-left text-300">
                    <Password
                      class="login-inputtext text-300"
                      id="password2"
                      v-model="v$.password2.$model"
                      :class="{
                        'p-invalid': v$.password2.$invalid && submitted,
                      }"
                      :feedback="false"
                      toggleMask
                    >
                    </Password>
                    <label
                      class="login-float font-normal text-300"
                      for="password2"
                      :class="{ 'p-error': v$.password2.$invalid && submitted }"
                      >Confirmar contraseña *</label
                    >
                  </div>
                  <span v-if="v$.password2.$error && submitted">
                    <span
                      id="email-error"
                      v-for="(error, index) of v$.password2.$errors"
                      :key="index"
                    >
                      <small class="p-error">{{ error.$message }}</small>
                    </span>
                  </span>
                  <small
                    v-else-if="
                      (v$.password2.$invalid && submitted) ||
                      v$.password2.$pending.$response
                    "
                    class="p-error"
                    >{{
                      v$.password2.required.$message.replace(
                        "Value",
                        "Password confirmation"
                      )
                    }}</small
                  >
                </div>

                <!--  -->

                <!--  -->
                <div class="field-checkbox font-normal text-300">
                  <Checkbox
                    id="accept"
                    name="accept"
                    :binary="true"
                    v-model="accept"
                    :class="{ 'p-invalid': v$.accept.$invalid && submitted }"
                  />
                  <label
                    for="accept"
                    :class="{ 'p-error': v$.accept.$invalid && submitted }"
                    >Estoy de acuerdo con los
                    <b>Términos y condiciones </b> *</label
                  >
                </div>
                <Button
                  type="submit"
                  label="Completar Registro"
                  class="button-fondo text-2xl mt-2"
                  :loading="loading"
                  :disabled="v$.$invalid"
                />
              </div>
              <!-- Step OTP validation -->
              <div class="text-300 text-center" v-if="validate_otp">
                <OtpValidationForm
                  ref=" pin_form"
                  :message="`<h4 class='text-center mb-3'>Tu cédula y teléfono ya están registrados.</h4>Ingrese el código de validación enviado por WhatsApp al teléfono <b>${formatted_phone_number}</b>, para completar el registro y activar tu cuenta.`"
                  :code_digits="6"
                  :sending_validation_code="sending_validation_code"
                  @cancel="resetFormSteps"
                  @sendValidationCode="validateOTP"
                />
              </div>
            </form>
            <br />
            <br />
            <p class="text-center font-normal text-300">
              ¿Ya tienes una cuenta en Kiosqo?
              <router-link
                class="font-medium no-underline ml-2 text-right cursor-pointer"
                style="color: #cc195a"
                to="login"
                >Inicia sesión
              </router-link>
            </p>
          </div>
          <div class="text-center mb-5">
            <div class="text-300 text-xl font-medium mb-3">
              ¿Quieres jugar como Invitado?
            </div>
            <span class="text-400 font-medium">puedes hacerlo por aquí</span>
            <Button
              label="Autogestión"
              @click="selfmanagement"
              class="button-fondo w-full mt-3 text-xl"
              type="submit"
            >
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { email, required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import OtpValidationForm from "@/components/ticketoffice/forms/OtpValidationForm.vue";
import "maz-ui/css/main.css";
import messages from "../mixins/messages";
import HTTP from "@/service/http";
import Dropdown from "primevue/dropdown";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [messages],
  components: {
    MazPhoneNumberInput,
    OtpValidationForm,
    Dropdown,
  },
  data() {
    return {
      stateCountry: "",
      stateCountrys: ["maturin"],
      phone_number: "",
      formatted_phone_number: "",
      phone_valid: false,
      email: "",
      password: "",
      password2: "",
      person_names: "",
      person_lastnames: "",
      dni_used: false,
      cedula: "",
      accept: false,
      submitted: false,
      loading: false,
      step1_check_dni: true,
      step2_partial_register_form: false,
      step3_complete_register_form: false,
      validate_otp: false,
      validation_code: "",
      checkingDni: false,
      sending_validation_code: false,
      dni_user_active: false,
      phone_translations: {
        class: "login-inputtext text-300 font-normal",
        countrySelector: {
          placeholder: "Código País",
          error: "Elija un país",
        },
        phoneInput: {
          placeholder: "Numéro de teléfono",
          example: "Ejemplo:",
        },
      },
    };
  },
  mounted() {
    this.resetFormSteps();
    this.getStateCountrys();
  },
  methods: {
    getStateCountrys() {
      this.stateCountrys = ["maturin", "caracas"];

      HTTP(true)
        .get(`/directions/states`)
        .then((response) => {
          this.stateCountrys = response.data.data;
          console.log("estados", response.data.data);
        });
    },
    selfmanagement() {
      // Redireccionar a autogestion de Kiosqo.tech
      window.location.href = process.env.VUE_APP_SELFMANAGEMENT_URL;
    },
    resetFormSteps() {
      this.step1_check_dni = true;
      this.step2_partial_register_form = false;
      this.step3_complete_register_form = false;
      this.validate_otp = false;
      this.dni_used = false;
      this.dni_user_active = false;
      this.cedula = "";
      this.person_names = "";
      this.person_lastnames = "";
      this.phone_number = "";
      this.formatted_phone_number = "";
      this.phone_valid = false;
      this.email = "";
      this.password = "";
      this.password2 = "";
      this.validation_code = "";
      this.accept = false;
    },
    updatePhoneNumber(value) {
      this.phone_valid = value.isValid;
    },
    checkCedula() {
      if (this.cedula) {
        this.checkingDni = true;
        HTTP(true)
          .get(`/persons/register?dni=${this.cedula}`)
          .then((resp) => {
            this.person_names = resp.data.data.person_name;
            this.email = resp.data.data.email;
            this.dni_used = true;
            this.step1_check_dni = false;
            this.step2_partial_register_form = false;
            this.formatted_phone_number = resp.data.data.phone_number;
            this.validate_otp = true;
          })
          .catch((err) => {
            if (err && err.response && err.response.status === 404) {
              // if the status is 404 then the user dni is not found, proceed to step 2
              this.dni_used = false;
              this.step1_check_dni = false;
              this.step2_partial_register_form = true;
            } else if (
              err &&
              err.response &&
              err.response.status === 400 &&
              err.response.data.message.includes("usuario activo")
            ) {
              // if the status is 400 and response message indicate an active user then show the message
              this.resetFormSteps();
              this.dni_user_active = true;
            } else if (err.response)
              this.showToast({
                severity: "error",
                summary: "Error",
                detail:
                  this.getFirstError(err.response.data.errors) ||
                  err.response.data.message,
                life: 3000,
              });
          })
          .finally(() => {
            this.checkingDni = false;
            this.submitted = false;
          });
      } else return;
    },
    handleSubmit(isFormValid) {
      this.submitted = true;
      if (!isFormValid) return;
      if (!this.dni_used && this.step2_partial_register_form) {
        this.partialUserRegister();
      } else if (this.dni_used && this.step3_complete_register_form) {
        this.completeUserRegister();
      } else {
        this.checkCedula();
      }
    },
    partialUserRegister() {
      this.loading = true;
      this.$store
        .dispatch("auth/partial_signup", {
          dni: this.cedula,
          first_name: this.person_names,
          last_name: this.person_lastnames,
          fk_contact: {
            phone_number: this.phone_number,
          },
          fk_province: this.stateCountry.id,
        })
        .then((resp) => {
          this.showToast({
            severity: "info",
            detail: resp.data.message,
            life: 3000,
          });
          this.step2_partial_register_form = false;
          this.dni_used = true;
          this.formatted_phone_number = resp.data.data.phone_number;
          this.validate_otp = true;
        })
        .catch((err) => {
          if (err.response)
            this.showToast({
              severity: "error",
              summary: "Error",
              detail:
                this.getFirstError(err.response.data.errors) ||
                err.response.data.message,
              life: 3000,
            });
        })
        .finally(() => {
          this.loading = false;
          this.submitted = false;
        });
    },
    validateOTP(token) {
      if (!token) return;
      this.sending_validation_code = true;
      HTTP(true)
        .post("/persons/register/otp", {
          dni: this.cedula,
          verification_code: token,
        })
        .then(() => {
          this.step3_complete_register_form = true;
          this.validate_otp = false;
          this.validation_code = token;
        })
        .catch((err) => {
          if (err.response) {
            this.$refs.pin_form.clearValidationCode();
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              summary: err.response.data.message,
              detail:
                this.getFirstError(err.response.data.errors) ||
                err.response.data.message,
              life: 10000,
            });
          }
        })
        .finally(() => {
          this.sending_validation_code = false;
        });
    },
    completeUserRegister() {
      this.loading = true;
      HTTP(true)
        .post("/persons/register", {
          dni: this.cedula,
          user: {
            email: this.email,
            password: this.password,
            repeat_password: this.password2,
          },
          verification_code: this.validation_code,
        })
        .then((resp) => {
          this.showToast({
            severity: "info",
            detail: resp.data.message,
            life: 3000,
          });
          this.step3_complete_register_form = false;
          this.dni_user_active = true;
        })
        .catch((err) => {
          if (err.response)
            this.showToast({
              severity: "error",
              summary: "Error",
              detail:
                this.getFirstError(err.response.data.errors) ||
                err.response.data.message,
              life: 3000,
            });
        })
        .finally(() => {
          this.loading = false;
          this.submitted = false;
        });
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
  validations() {
    if (this.step1_check_dni) {
      return {
        cedula: {
          required,
        },
      };
    } else if (!this.dni_used && this.step2_partial_register_form) {
      return {
        person_names: {
          required,
        },
        person_lastnames: {
          required,
        },
        phone_number: {
          required,
          phone: () => {
            return this.phone_valid;
          },
        },
        accept: {
          accept_terms: (value) => {
            return value === true;
          },
        },
      };
    } else
      return {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
        password2: {
          required,
          sameAsPassword: sameAs(this.password),
        },
        accept: {
          accept_terms: (value) => {
            return value === true;
          },
        },
      };
  },
};
</script>
<style lang="scss" scoped>
.field {
  margin-bottom: 1.8rem;
}

.background-marco-signup {
  // background: linear-gradient(to right bottom, #fefefe00, rgb(255 255 255 / 10%));
  box-shadow: inset 0 0 0 400px rgb(255 255 255 / 5%);
  backdrop-filter: blur(10px);
}

.style-background {
  background: radial-gradient(50% 50% at 50% 50%, #461ec8 0%, #25007a 100%);
}

.signup-background {
  background: url(/public/layout/images/wallpaper-login_signup.webp);
  background-size: cover;
}

.style-message {
  background: rgb(76 175 80 / 75%) !important;
  text-shadow: #25007a 1px 1px 3px;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
}

.login-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}

.input-phone-label {
  position: absolute;
  color: #e0e0e0;
  top: -10px;
  left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: white;
  z-index: 4;
  font-size: 12px;
}

:deep(.maz-border) {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.38) !important;
}

:deep(.maz-rounded-lg) {
  border-radius: 0.4rem;
  background: rgba(0, 0, 0, 0) !important;
  border: 0px solid rgba(0, 0, 0, 0.38);
}

:deep(.--is-focused .maz-border) {
  border-color: #e60000 !important;
}

:deep(.m-phone-number-input) {
  &:has(.m-input.--is-focused) .m-input-wrapper.maz-border {
    border-color: #e60000 !important;
  }
}
</style>
