export default {
  methods: {
    getFirstError(errors) {
      if (errors) {
        if (Array.isArray(errors)) return errors.length ? this.getFirstError(errors[0]) : '';
        else if (typeof errors === 'object')
          return Object.keys(errors).length ? this.getFirstError(errors[Object.keys(errors)[0]]) : '';
        else if (typeof errors === 'string') return errors;
      } else return '';
      /* return errors && errors.length > 0 && typeof errors[0] === 'object'
        ? typeof errors[0][Object.keys(errors[0])[0]] === 'object'
          ? errors[0][Object.keys(errors[0])][0]
          : errors[0][Object.keys(errors[0])]
        : ''; */
    },
  },
};
